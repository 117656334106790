import { ClickAwayListener, Tooltip, TooltipProps } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import * as React from 'react'
import colors from 'theme/colors'

export type InfoTooltipProps = Omit<TooltipProps, 'children'> & { iconStyle?: React.CSSProperties }

const InfoTooltip = (props: InfoTooltipProps): JSX.Element => {
    const [isOpen, setOpen] = React.useState(false)
    const { placement, title, iconStyle, ...rest } = props

    return (
        <ClickAwayListener onClickAway={(): void => setOpen(false)} mouseEvent={'onMouseDown'}>
            <Tooltip
                disableFocusListener={true}
                disableHoverListener={true}
                disableTouchListener={true}
                PopperProps={{ disablePortal: true }}
                open={isOpen}
                placement={placement ? placement : 'bottom-end'}
                title={<div dangerouslySetInnerHTML={{ __html: title as string }} />}
                {...rest}
            >
                <InfoOutlinedIcon
                    style={
                        iconStyle ? { color: colors.basketInfoIcon, ...iconStyle } : { color: colors.basketInfoIcon }
                    }
                    onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
                        if (typeof event.stopPropagation === 'function') {
                            event.stopPropagation()
                        }
                        setOpen(!isOpen)
                    }}
                />
            </Tooltip>
        </ClickAwayListener>
    )
}

export default InfoTooltip
