import gql from 'graphql-tag'

export default gql`
    query LoadCATVData($data: String!, $productGroups: [String!]!) {
        loadCATVData(data: $data, productGroups: $productGroups) {
            status
            message
            data {
                CustomerID
                Salutation
                Title
                FirstName
                LastName
                Company
                CompanyLegalForm
                Email
                Birthdate
                Telephone
                Mobile
                ConnectionAddress {
                    Salutation
                    Title
                    FirstName
                    LastName
                    Street
                    HouseNumber
                    ZipCode
                    City
                }
                BillingAddress {
                    Salutation
                    Title
                    FirstName
                    LastName
                    Street
                    HouseNumber
                    ZipCode
                    City
                }
                AccountHolderAddress {
                    Salutation
                    Title
                    FirstName
                    LastName
                    Street
                    HouseNumber
                    ZipCode
                    City
                }
                BankData {
                    AccountHolder
                    IsTransfer
                    Iban
                }
            }
            productCategories {
                id
                title
                identifier
                subtitle
                description
                priceText
                sortIndex
                products {
                    id
                    title
                    clientID
                    startOfContract
                    titleBasket
                    subtitle
                    description
                    dataSheet
                    subCategory
                    descriptionBasket
                    badgeIdentifier
                    contractLength
                    information
                    billMode
                    included
                    excludedArticles
                    requiredArticles
                    gross
                    priceNet
                    priceVat
                    sortIndex
                    footnoteText
                    minimumContractPeriod
                    cancellationPeriod
                    contractExtension
                    fees {
                        id
                        title
                        titleBasket
                        subtitle
                        description
                        descriptionBasket
                        billMode
                        included
                        gross
                        priceNet
                        priceVat
                        feeLength
                        processUnique
                        optional
                        monthlyFees {
                            initMonth
                            endMonth
                            priceNet
                            priceVat
                        }
                        discounts {
                            id
                            title
                            titleBasket
                            subtitle
                            description
                            descriptionBasket
                            billMode
                            gross
                            priceNet
                            priceVat
                            monthlyDiscounts {
                                initMonth
                                endMonth
                                gross
                                priceNet
                                priceVat
                            }
                            discountLength
                        }
                    }
                    discounts {
                        id
                        title
                        titleBasket
                        subtitle
                        description
                        descriptionBasket
                        billMode
                        gross
                        priceNet
                        priceVat
                        monthlyDiscounts {
                            initMonth
                            endMonth
                            gross
                            priceNet
                            priceVat
                        }
                        discountLength
                    }
                    productTypes {
                        id
                        title
                        identifier
                        subtitle
                        information
                        dataSheet
                        description
                        billMode
                        included
                        gross
                        priceNet
                        priceVat
                        sortIndex
                        optional
                        footnoteText
                        upload
                        uploadStandard
                        uploadMinimal
                        download
                        downloadStandard
                        downloadMinimal
                        discounts {
                            id
                            title
                            titleBasket
                            subtitle
                            description
                            descriptionBasket
                            billMode
                            gross
                            priceNet
                            priceVat
                            monthlyDiscounts {
                                initMonth
                                endMonth
                                gross
                                priceNet
                                priceVat
                            }
                            discountLength
                        }
                        category {
                            id
                            title
                            type
                            identifier
                            titleBasket
                            information
                            max
                            min
                            sortIndex
                            footnoteText
                            options {
                                id
                                title
                                titleBasket
                                subtitle
                                dataSheet
                                description
                                descriptionBasket
                                badgeIdentifier
                                information
                                billMode
                                gross
                                priceNet
                                priceVat
                                excludedArticles
                                requiredArticles
                                preconditionArticle
                                included
                                isDefault
                                contractLength
                                max
                                min
                                sortIndex
                                footnoteText
                                minimumContractPeriod
                                cancellationPeriod
                                contractExtension
                                isHardware
                                discounts {
                                    id
                                    title
                                    titleBasket
                                    subtitle
                                    description
                                    descriptionBasket
                                    billMode
                                    gross
                                    priceNet
                                    priceVat
                                    monthlyDiscounts {
                                        initMonth
                                        endMonth
                                        gross
                                        priceNet
                                        priceVat
                                    }
                                    discountLength
                                }
                                fees {
                                    id
                                    title
                                    titleBasket
                                    subtitle
                                    description
                                    descriptionBasket
                                    billMode
                                    included
                                    gross
                                    priceNet
                                    priceVat
                                    feeLength
                                    processUnique
                                    optional
                                    monthlyFees {
                                        initMonth
                                        endMonth
                                        priceNet
                                        priceVat
                                    }
                                    discounts {
                                        id
                                        title
                                        titleBasket
                                        subtitle
                                        description
                                        descriptionBasket
                                        billMode
                                        gross
                                        priceNet
                                        priceVat
                                        monthlyDiscounts {
                                            initMonth
                                            endMonth
                                            gross
                                            priceNet
                                            priceVat
                                        }
                                        discountLength
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
