import { Box } from '@material-ui/core'
import OptionItem, { OptionItemContent } from 'components/OptionItem/OptionItem'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'

export interface RadioOptionGroupProps {
    options: OptionItemContent[]
    selected: string
    onSelect: (id: string) => void
}

const RadioOptionGroup = (props: RadioOptionGroupProps): JSX.Element => {
    const { options, selected, onSelect } = props
    return (
        <Box width={1}>
            {options.map(
                (option: OptionItemContent): JSX.Element => (
                    <Box mb={'9px'} key={option.id + option.title}>
                        <OptionItem
                            content={option}
                            selected={selected === option.id}
                            type={OptionType.RADIO}
                            onSelect={onSelect}
                        />
                    </Box>
                ),
            )}
        </Box>
    )
}

export default RadioOptionGroup
