import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import ErrorBox from 'components/ErrorBox'
import { SaveOrderProgessState } from 'components/SaveProgress/useSaveProgressState'
import { useTranslation } from 'react-i18next'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { ETrackerCodeList } from 'utils/etrackerCssCodes'
import useInactiveDialogState from './useInactiveDialogState'

const useStyles = makeStyles(
    () => ({
        paper: {
            borderRadius: '15px',
            zIndex: 1000,
        },
    }),
    { name: 'InactiveDialog' },
)
const InactiveDialog = (): JSX.Element | null => {
    const { t } = useTranslation()
    const classes = useStyles()
    const {
        currentPage,
        email,
        errorState,
        mailValid,
        open,
        pagesList,
        saveOrderProgessState,
        handleClose,
        handleSend,
        dataPrivacyChecked,
        setDataPrivacyChecked,
        confirmEmail,
        setConfirmEmail,
        emailError,
        setEmailError,
        setEmail,
        vzfID,
    } = useInactiveDialogState()

    if (
        pagesList[currentPage].path == '/' ||
        pagesList[currentPage].path == '/' + t(ViewType.PRODUCT_CATEGORIES_SELECTION) ||
        pagesList[currentPage].path.includes(t(ViewType.PRODUCT_SELECTION)) ||
        pagesList[currentPage].path.includes(t(ViewType.OPTIONS_CONFIGURATION)) ||
        pagesList[currentPage].path == '/' + t(ViewType.ORDER_OVERVIEW) ||
        pagesList[currentPage].path == '/' + t(ViewType.ORDER_CONFIRMATION) ||
        pagesList[currentPage].path == '/' + t(ViewType.VZF_VIEW) ||
        pagesList[currentPage].path == '/' + t(ViewType.VZF_LANDINGPAGE) ||
        vzfID === ''
    ) {
        return null
    }

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            maxWidth={'md'}
            fullWidth={true}
            open={open}
            onClose={handleClose}
            style={{ zIndex: 9999 }}
        >
            <DialogTitle style={{ textAlign: 'center' }} disableTypography={true}>
                <Typography variant={'h1'}>{t('inactiveHeader')}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={'h4'}>{t('inactiveSubHeader')}</Typography>
                {errorState.trim().length > 0 && (
                    <Grid container item xs={12} lg={9} xl={7}>
                        <ErrorBox errors={[{ category: '', errors: [errorState] }]} />
                    </Grid>
                )}
            </DialogContent>
            <DialogActions style={{ padding: 24 }}>
                <Grid container item spacing={2} alignItems={'center'}>
                    <Grid item xs={12}>
                        <TextField
                            onChange={(e) => {
                                if (emailError && e.target.value === confirmEmail) {
                                    setEmailError(false)
                                }
                                setEmail(e.target.value)
                            }}
                            label={t('inactiveEmailLabel')}
                            value={email}
                            placeholder={'max.musterman@e-mail.de'}
                            variant={'outlined'}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            onPaste={(e) => {
                                e.preventDefault()
                            }}
                            onChange={(e) => {
                                if (emailError && e.target.value === email) {
                                    setEmailError(false)
                                }
                                setConfirmEmail(e.target.value)
                            }}
                            label={t('confirmEmail')}
                            value={confirmEmail}
                            variant={'outlined'}
                            fullWidth
                            helperText={emailError && t('emailNotMatching')}
                            error={emailError}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Checkbox
                            style={{ backgroundColor: 'rgba(241,238,238,1)', borderColor: 'red' }}
                            color="primary"
                            checked={dataPrivacyChecked}
                            onClick={() => setDataPrivacyChecked(!dataPrivacyChecked)}
                        />
                        <Typography
                            style={{ display: 'inline', paddingLeft: 12 }}
                            dangerouslySetInnerHTML={{ __html: t('saveOrderProgressConsent') }}
                        />
                    </Grid>

                    <Grid container item xs={12} sm={6} alignItems={'center'}>
                        <Button
                            disableFocusRipple={true}
                            className={clsx(['FormButton', 'BackButton'])}
                            onClick={handleClose}
                        >
                            {t('inactiveClose')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} className={'SaveProgressButton'}>
                        <Button
                            onClick={handleSend}
                            className={clsx([
                                'FormButton',
                                'NextButton',
                                ETrackerCodeList.later,
                                saveOrderProgessState === SaveOrderProgessState.SENDING && 'Loading',
                                saveOrderProgessState === SaveOrderProgessState.SENT && 'Send',
                            ])}
                            fullWidth
                            disabled={
                                !(
                                    mailValid &&
                                    dataPrivacyChecked &&
                                    saveOrderProgessState === SaveOrderProgessState.READY
                                )
                            }
                        >
                            {saveOrderProgessState === SaveOrderProgessState.READY && t('inactiveOk')}
                            {saveOrderProgessState === SaveOrderProgessState.SENDING && (
                                <>
                                    {t('saveOrderProgressSending')}
                                    <div className={'animation-loader'} />
                                </>
                            )}
                            {saveOrderProgessState === SaveOrderProgessState.SENT && t('saveOrderProgressSent')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default InactiveDialog
