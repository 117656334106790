import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import { Customize } from 'graphql/types'
import { Dispatch, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { ETrackerCodeList } from 'utils/etrackerCssCodes'

interface CrossSellingReducerReturn {
    radioOptions: ConfigurationItemOption[]
    currentView: ViewType
    selectedCrossSellingOption: string
    targetId: string
    customizeJsData: Customize | undefined
    setCrossSellingOption: (option: string) => void
}

interface CrossSellingParam {
    targetId: string
}

export const useCrossSelling: () => CrossSellingReducerReturn = () => {
    const { B2B } = useURLParams()
    const { t } = useTranslation()
    const { targetId } = useParams<CrossSellingParam>()
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const { currentView, crossSoldProductCategories, customizeJsData } = useSelector((appState: AppState) => {
        return {
            currentView: appState.generalState.currentView,
            crossSoldProductCategories: appState.generalState.crossSoldProductCategories,
            customizeJsData: appState.generalState.customizeJsData,
        }
    })

    const [selectedCrossSellingOption, setSelectedCrossSellingOption] = useState(
        crossSoldProductCategories.includes(targetId) ? 'true' : 'false',
    )

    const setSelectedProductCategories = useCallback(
        (payload: { id: string; B2B: boolean }) => {
            dispatch({ type: GeneralStateAction.SELECT_PRODUCT_CATEGORY, payload })
        },
        [dispatch],
    )

    const setSelectedCrossSellProductCategory = useCallback(
        (payload: string) => {
            dispatch({ type: GeneralStateAction.SELECT_CROSS_SELL_PRODUCT, payload })
        },
        [dispatch],
    )

    const radioOptions: ConfigurationItemOption[] = [
        {
            columnValue: 12,
            label: t('productCrossSellingStrings.yes'),
            mobileColumnValue: 12,
            value: 'true',
        },
        {
            columnValue: 12,
            label: t('productCrossSellingStrings.no'),
            mobileColumnValue: 12,
            value: 'false',
        },
    ]

    const setCrossSellingOption = (option: string) => {
        if (option !== selectedCrossSellingOption) {
            setSelectedCrossSellingOption(option)
            setSelectedCrossSellProductCategory(targetId)
            setSelectedProductCategories({ id: targetId, B2B })
        }
    }

    switch (targetId) {
        case 'television':
            radioOptions[0].className = ETrackerCodeList.cross_selling_tv_yes
            radioOptions[1].className = ETrackerCodeList.cross_selling_tv_no
            break
        case 'internet':
            radioOptions[0].className = ETrackerCodeList.cross_selling_internet_yes
            radioOptions[1].className = ETrackerCodeList.cross_selling_internet_no
            break
        case 'telephone':
            radioOptions[0].className = ETrackerCodeList.cross_selling_telephone_yes
            radioOptions[1].className = ETrackerCodeList.cross_selling_telephone_no
            break
    }

    return {
        radioOptions,
        currentView,
        selectedCrossSellingOption,
        targetId,
        customizeJsData,
        setCrossSellingOption,
    }
}
