import gql from 'graphql-tag'

export default gql`
    mutation SaveOrderProgress(
        $email: String!
        $data: String!
        $key: String!
        $salutation: String!
        $vzfID: String!
        $clientID: Int
        $orderProcessType: String!
    ) {
        saveOrderProgress(
            email: $email
            data: $data
            key: $key
            salutation: $salutation
            vzfID: $vzfID
            clientID: $clientID
            orderProcessType: $orderProcessType
        ) {
            status
        }
    }
`
