import { Box, Button, CircularProgress, Collapse, Fade, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import ErrorBox from 'components/ErrorBox'
import { useTranslation } from 'react-i18next'
import { Error, LoadState, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { getTestIDOnViewType } from 'utils/TestIDs'

export interface EditViewWrapperProps {
    children: JSX.Element
    header: string
    viewType: ViewType
    errorCategory?: string | ViewType
    disabledSubmit: boolean
    loadState: LoadState
    errors: Error[]
    onBack: () => void
    onSubmit: () => void
    disabledBack?: boolean
    submitButtonTextAlt?: boolean
}

const EditViewWrapper = (props: EditViewWrapperProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        children,
        header,
        viewType,
        errorCategory,
        disabledSubmit,
        errors,
        loadState,
        onBack,
        onSubmit,
        disabledBack,
        submitButtonTextAlt,
    } = props
    const newErrorCategory = errorCategory

    return (
        <Box paddingLeft={3} paddingRight={3} paddingTop={2} paddingBottom={2} minHeight={'calc(100vh - 194px)'}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={10}>
                    <Typography
                        {...getTestIDOnViewType(viewType, 'HEADER_TYPOGRAPHY')}
                        variant={'h1'}
                        className={clsx({ TextError: !loadState.loading && loadState.errors })}
                        dangerouslySetInnerHTML={{
                            __html: !loadState.loading && loadState.errors ? t(loadState.errors) : t(header),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={10}>
                    <Grid container item xs={12} lg={10} xl={9} direction={'column'} justifyContent={'space-between'}>
                        <Fade
                            in={loadState.loading}
                            timeout={250}
                            unmountOnExit={true}
                            exit={false}
                            disableStrictModeCompat={true}
                        >
                            <Box
                                height={1}
                                width={1}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                marginTop={5}
                                marginBottom={{ xs: 5, sm: 0 }}
                            >
                                <CircularProgress size={80} />
                            </Box>
                        </Fade>
                        <Fade
                            in={!loadState.loading}
                            timeout={250}
                            unmountOnExit={true}
                            exit={false}
                            disableStrictModeCompat={true}
                        >
                            <Box>
                                {children}

                                <Collapse
                                    in={errors.length > 0}
                                    unmountOnExit={true}
                                    timeout={250}
                                    disableStrictModeCompat={true}
                                >
                                    <Grid container item xs={12}>
                                        <ErrorBox errors={errors} categoryToShow={newErrorCategory} />
                                    </Grid>
                                </Collapse>

                                <Box width={1} marginTop={2} display={'flex'} justifyContent={'space-between'}>
                                    <Grid container justifyContent={'space-between'}>
                                        <Grid item xs={12} sm>
                                            <Button
                                                disableFocusRipple={true}
                                                {...getTestIDOnViewType(viewType, 'BACK_BUTTON')}
                                                className={clsx(['FormButton', 'BackButton'])}
                                                onClick={onBack}
                                                disabled={disabledBack ? disabledBack : false}
                                            >
                                                {t('goBack')}
                                            </Button>
                                        </Grid>
                                        <Grid container item xs={12} sm justifyContent={'flex-end'}>
                                            <Button
                                                disableFocusRipple={true}
                                                {...getTestIDOnViewType(viewType, 'SUBMIT_BUTTON')}
                                                disabled={disabledSubmit}
                                                className={clsx(['FormButton', 'NextButton'])}
                                                onClick={onSubmit}
                                            >
                                                {submitButtonTextAlt && submitButtonTextAlt
                                                    ? t(`editPage.submitButtonAlt`)
                                                    : t(`editPage.submitButton`)}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EditViewWrapper
