import { Box, Typography } from '@material-ui/core'
import React from 'react'
import colors from 'theme/colors'

export interface InputCounterProps {
    id: string
    min?: number
    max?: number
    count?: number
    increase?: (id: string, min: number, max: number) => void
    decrease?: (id: string, min: number, max: number) => void
}

const InputCounter = (props: InputCounterProps): JSX.Element => {
    const { id, count, min, max, increase, decrease } = props
    return (
        <Box
            display="flex"
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection="row"
            width={86}
            border="2px solid #F3F0F0"
            textAlign="center"
            borderRadius="4px"
            style={{ backgroundColor: colors.white }}
        >
            <Box
                flex={1}
                style={{ backgroundColor: colors.lightGray, cursor: 'pointer' }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation()
                    if (decrease && min && max) {
                        decrease(id, min, max)
                    }
                }}
            >
                <Typography variant="body2" style={{ color: colors.black }}>
                    -
                </Typography>
            </Box>
            <Box flex={1}>
                <Typography style={{ color: colors.black }}>{count ? count : ''}</Typography>
            </Box>
            <Box
                flex={1}
                style={{ backgroundColor: colors.lightGray, cursor: 'pointer' }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation()
                    if (increase && min && max) {
                        increase(id, min, max)
                    }
                }}
            >
                <Typography variant="body2" style={{ color: colors.black }}>
                    +
                </Typography>
            </Box>
        </Box>
    )
}

export default InputCounter
