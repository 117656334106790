import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Divider,
    Fade,
    Grid,
    Hidden,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import ErrorBox from 'components/ErrorBox/ErrorBox'
import { useTranslation } from 'react-i18next'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import colors from 'theme/colors'
import { ETrackerCodeList } from 'utils/etrackerCssCodes'
import { mailIsValid } from 'utils/testable/functions'
import useSaveProgressState, { SaveOrderProgessState } from './useSaveProgressState'

const useStyles = makeStyles(
    () => ({
        saveProgressIconOpen: {
            transform: 'rotate(-180deg)',
        },
        saveProgressIcon: {
            transition: 'transform 300ms ease-in-out',
        },
    }),
    { name: 'SaveProgress' },
)

export interface SaveProgressProps {
    checkboxLabel: string
}

const SaveProgress = (props: SaveProgressProps): JSX.Element | null => {
    const {
        currentPage,
        dataPrivacyChecked,
        email,
        errorState,
        onSendClick,
        open,
        pagesList,
        saveOrderProgessState,
        setDataPrivacyChecked,
        setEmail,
        setOpen,
        state,
        confirmEmail,
        setConfirmEmail,
        emailError,
        setEmailError,
        vzfID,
    } = useSaveProgressState()

    const { checkboxLabel } = props
    const classes = useStyles()

    const mailValid = mailIsValid(email)

    const { t } = useTranslation()
    if (
        pagesList[currentPage].path == '/' ||
        pagesList[currentPage].path == '/' + t(ViewType.PRODUCT_CATEGORIES_SELECTION) ||
        pagesList[currentPage].path.includes(t(ViewType.PRODUCT_SELECTION)) ||
        pagesList[currentPage].path.includes(t(ViewType.OPTIONS_CONFIGURATION)) ||
        pagesList[currentPage].path.includes(t(ViewType.PRODUCT_CROSS_SELLING)) ||
        pagesList[currentPage].path.includes(t(ViewType.PRODUCT_TYPE_SELECTION)) ||
        pagesList[currentPage].path == '/' + t(ViewType.ORDER_OVERVIEW) ||
        pagesList[currentPage].path == '/' + t(ViewType.ORDER_CONFIRMATION) ||
        pagesList[currentPage].path == '/' + t(ViewType.VZF_VIEW) ||
        pagesList[currentPage].path == '/' + t(ViewType.VZF_LANDINGPAGE) ||
        vzfID === ''
    ) {
        return null
    }

    return (
        <Box width={1} bgcolor={colors.midGray}>
            <Box
                width={1}
                height={50}
                onClick={(): void => setOpen(!open)}
                style={{
                    cursor: 'pointer',
                }}
                display={'flex'}
                alignItems={'center'}
            >
                <Grid container>
                    <Hidden xsDown={true}>
                        <Grid item md={2} />
                    </Hidden>
                    <Grid item xs={12} md={10}>
                        <Box flex={1} pl={3} pr={3}>
                            <Grid
                                container
                                item
                                xs={12}
                                lg={9}
                                xl={7}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <Typography variant={'h5'} className={'SaveProgressTitle'}>
                                    {t('continueOrderLater')}
                                </Typography>
                                <img
                                    width={'24px'}
                                    height={'24px'}
                                    className={
                                        open
                                            ? clsx([classes.saveProgressIcon, classes.saveProgressIconOpen])
                                            : clsx(classes.saveProgressIcon)
                                    }
                                    src={process.env.PUBLIC_URL + '/customize/saveProgressIcon.svg'}
                                    alt={'/\\'}
                                />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Collapse in={open} style={{ paddingBottom: open ? '12px' : 0 }}>
                <Grid container className={'SaveProgressClickWrapper'}>
                    <Grid item xs={12} md={2} />
                    <Grid item xs={12} md={10}>
                        <Box flex={1} pl={3} pr={3}>
                            <Grid container>
                                <Grid item xs={12} lg={9} xl={7}>
                                    <Fade in={open}>
                                        <Divider className={'SaveProgressDivider'} />
                                    </Fade>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box flex={1} pl={3} pr={3} pt={{ xs: 2, sm: 4 }}>
                            {errorState.trim().length > 0 && (
                                <Grid container item xs={12} lg={9} xl={7}>
                                    <ErrorBox errors={[{ category: '', errors: [errorState] }]} />
                                </Grid>
                            )}
                            <Grid container item xs={12} lg={9} xl={7} justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={4}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Grid item xs={12}>
                                            <TextField
                                                onChange={(e): void => {
                                                    if (emailError && e.target.value === confirmEmail) {
                                                        setEmailError(false)
                                                    }
                                                    setEmail(e.currentTarget.value)
                                                }}
                                                label={t('email')}
                                                size={'small'}
                                                value={email}
                                                variant={'outlined'}
                                                InputProps={{ className: 'SaveProgressMailInput' }}
                                                InputLabelProps={{ className: 'SaveProgressMailInput' }}
                                                fullWidth
                                                style={{ paddingBottom: 15 }}
                                                error={emailError}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                }}
                                                onChange={(e): void => {
                                                    if (emailError && e.target.value === email) {
                                                        setEmailError(false)
                                                    }
                                                    setConfirmEmail(e.currentTarget.value)
                                                }}
                                                label={t('confirmEmail')}
                                                size={'small'}
                                                value={confirmEmail}
                                                variant={'outlined'}
                                                InputProps={{ className: 'SaveProgressMailInput' }}
                                                InputLabelProps={{ className: 'SaveProgressMailInput' }}
                                                fullWidth
                                                helperText={emailError && t('emailNotMatching')}
                                                error={emailError}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    xs={12}
                                    sm
                                    className={'SaveProgressCheckbox'}
                                    onClick={(): void => setDataPrivacyChecked(!dataPrivacyChecked)}
                                >
                                    <Grid item>
                                        <Checkbox
                                            checked={dataPrivacyChecked}
                                            style={{ marginTop: '-3px', transform: 'scale(1.5)' }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant={'subtitle2'}
                                            className={'DataPrivacyLabel'}
                                            dangerouslySetInnerHTML={{ __html: checkboxLabel }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} className={'SaveProgressButton'}>
                                    <Button
                                        onClick={(): void => onSendClick(state, email, confirmEmail)}
                                        className={clsx([
                                            'SaveProgressButton',
                                            'NextButton',
                                            ETrackerCodeList.later,
                                            saveOrderProgessState === SaveOrderProgessState.SENDING && 'Loading',
                                            saveOrderProgessState === SaveOrderProgessState.SENT && 'Send',
                                        ])}
                                        fullWidth
                                        disabled={
                                            !(
                                                mailValid &&
                                                dataPrivacyChecked === true &&
                                                saveOrderProgessState === SaveOrderProgessState.READY
                                            )
                                        }
                                    >
                                        {saveOrderProgessState === SaveOrderProgessState.READY &&
                                            t('saveOrderProgressSend')}
                                        {saveOrderProgessState === SaveOrderProgessState.SENDING && (
                                            <>
                                                {t('saveOrderProgressSending')}
                                                <div className={'animation-loader'} />
                                            </>
                                        )}
                                        {saveOrderProgessState === SaveOrderProgessState.SENT &&
                                            t('saveOrderProgressSent')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Collapse>
        </Box>
    )
}

export default SaveProgress
