import { Box, Grid } from '@material-ui/core'
import ConfigurationItem from 'components/ConfigurationItem'
import ViewWrapper from 'components/ViewWrapper'
import { useCrossSelling } from 'pages/CrossSelling/useCrossSelling'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'

const CrossSelling = (): JSX.Element => {
    const [userInfo] = useUserInfo()
    const { currentView, radioOptions, selectedCrossSellingOption, targetId, customizeJsData, setCrossSellingOption } =
        useCrossSelling()

    return (
        <ViewWrapper
            header={getRoleAwareTranslationKey(
                userInfo,
                customizeJsData,
                currentView + 'Strings.' + targetId + '.title',
            )}
            subHeader={getRoleAwareTranslationKey(
                userInfo,
                customizeJsData,
                currentView + 'Strings.' + targetId + '.subheader',
            )}
            viewType={currentView}
            disabledSubmit={selectedCrossSellingOption === ''}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {radioOptions.map((item) => (
                        <Box key={item.value} marginTop={1}>
                            <ConfigurationItem
                                option={item}
                                className={item.className}
                                type={OptionType.RADIO}
                                paddingRight={'0px'}
                                selected={selectedCrossSellingOption === item.value}
                                onSelect={setCrossSellingOption}
                            />
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </ViewWrapper>
    )
}

export default CrossSelling
