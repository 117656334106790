import { Box, Divider, Grid, Typography } from '@material-ui/core'
import TaxInfo from 'components/TaxInfo'
import { BillMode } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import { formatCurrency } from 'utils/numberFormat'
import PriceDescription, { PriceDescriptionProps } from '../PriceDescription/PriceDescription'

export interface CostTableTotalDiscountEntry {
    name: string
    oldPrice: number
    newPrice: number
}

export interface CostTableTotalDiscount {
    sum: number
    discounts: CostTableTotalDiscountEntry[]
}

export interface CostTableRow {
    title: string
    value: PriceDescriptionProps
}

export interface CostsTableProps {
    marginTop?: number
    monthlyCost: number
    monthlyOldCost?: number
    unlimitedVoucherCost?: number
    monthlyIncrements: CostTableRow[]
    oneTimeCost: number
    oneTimeItems: CostTableRow[]
    isBasket?: boolean
    totalDiscounts: CostTableTotalDiscount
}

const CostsTable = (props: CostsTableProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        marginTop,
        monthlyCost,
        monthlyIncrements,
        oneTimeCost,
        oneTimeItems,
        isBasket,
        totalDiscounts,
        monthlyOldCost,
        unlimitedVoucherCost,
    } = props
    return (
        <Box className={'CostsTable'} padding={1} marginTop={marginTop !== undefined ? marginTop : 5} marginBottom={-1}>
            <Box width={1} height={'2px'} className={'CostsTableTopDivider'} style={{ opacity: 0 }} />
            <Grid container spacing={1}>
                <Grid item xs>
                    <Typography variant={'h5'} className={'CostsTableHeadline'}>
                        {t('monthlyCost')}
                    </Typography>
                </Grid>
                <Grid item xs={'auto'}>
                    <Box height={1} display={'flex'} alignItems={'center'}>
                        {monthlyOldCost && !isBasket && (
                            <Typography
                                variant={'h5'}
                                className={'OldPrice'}
                                style={{ fontWeight: 'bold', marginRight: '8px' }}
                            >
                                {formatCurrency(monthlyOldCost)}
                            </Typography>
                        )}
                        <Typography variant={'h5'} className={'CostsTableHeadline'}>
                            {formatCurrency(monthlyCost)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box width={1} marginTop={1} marginBottom={'4px'}>
                <Typography variant={'subtitle1'} className={'CostsTableSubtitle'}>
                    {t('startOfContract')}
                </Typography>
            </Box>
            {monthlyIncrements.map((tableRow: CostTableRow, index: number) => {
                return (
                    <Grid container key={index} className={'CostsTable'}>
                        <Grid item xs>
                            <Typography variant={'subtitle1'} className={'PriceDescriptionBasket'}>
                                {t('fromMonth', {
                                    month: tableRow.title,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <PriceDescription {...tableRow.value} isBasket={isBasket} isMonthlyIncrements={true} />
                        </Grid>
                    </Grid>
                )
            })}
            <Divider className={'CostsTableDivider'} />
            <Grid container spacing={1}>
                <Grid item xs>
                    <Typography variant={'h5'} className={'CostsTableHeadline'}>
                        {t('oneTimeCosts')}
                    </Typography>
                </Grid>
                <Grid item xs={'auto'}>
                    <Box height={1} display={'flex'} alignItems={'center'}>
                        <Typography variant={'h5'} className={'CostsTableHeadline'}>
                            {formatCurrency(oneTimeCost)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box width={1} marginTop={1} marginBottom={'4px'}>
                <Typography variant={'subtitle1'} className={'CostsTableSubtitle'}>
                    {t('yourOnlineAdvantage')}
                </Typography>
            </Box>
            {oneTimeItems.map((tableRow: CostTableRow, index: number) => (
                <Grid container key={index} className={'CostsTable'}>
                    <Grid item xs>
                        <Typography
                            className={'CostsTableRow'}
                            variant={'subtitle1'}
                            dangerouslySetInnerHTML={{ __html: t(tableRow.title) }}
                        />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <PriceDescription {...tableRow.value} isBasket={isBasket} isMonthlyIncrements={true} />
                    </Grid>
                </Grid>
            ))}
            {!isBasket && (
                <>
                    <TaxInfo
                        variant={'caption'}
                        style={{ paddingTop: 10, color: colors.white }}
                        displayFootNote={false}
                    />
                    <Divider className={'CostsTableDivider'} />
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <Typography variant={'h5'} className={'CostsTableHeadline'}>
                                {unlimitedVoucherCost && t('totalDiscountsOnce')}
                                {!unlimitedVoucherCost && t('totalDiscounts')}
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Box height={1} display={'flex'} alignItems={'center'}>
                                <Typography variant={'h5'} className={'CostsTableHeadline'}>
                                    {formatCurrency(totalDiscounts.sum)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {totalDiscounts.discounts.map((tableRow: CostTableTotalDiscountEntry, index: number) => {
                        return (
                            <Grid
                                container
                                key={index}
                                className={'CostsTable'}
                                style={{ marginTop: 4, alignItems: 'center' }}
                            >
                                <Grid item xs>
                                    <Typography variant={'subtitle1'} className={'PriceDescriptionBasket'}>
                                        {tableRow.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <PriceDescription
                                        newPrice={tableRow.newPrice}
                                        billMode={BillMode.IGNORE}
                                        isBasket={isBasket}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}
                    {unlimitedVoucherCost && (
                        <>
                            <Grid container spacing={1} style={{ marginTop: 32 }}>
                                <Grid item xs>
                                    <Typography variant={'h5'} className={'CostsTableHeadline'}>
                                        {t('unlimitedVoucherCostSaveHeadline')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Box height={1} display={'flex'} alignItems={'center'}>
                                        <Typography variant={'h5'} className={'CostsTableHeadline'}>
                                            {formatCurrency(unlimitedVoucherCost)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container className={'CostsTable'} style={{ marginTop: 4, alignItems: 'center' }}>
                                <Grid item xs>
                                    <Typography variant={'subtitle1'} className={'PriceDescriptionBasket'}>
                                        {t('unlimitedVoucherCostSave')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={'auto'} />
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Box>
    )
}

export default CostsTable
