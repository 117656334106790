import { useLazyQuery, useMutation } from '@apollo/client'
import SaveVZF from 'graphql/mutations/SaveVZF'
import SendVZFMail from 'graphql/mutations/SendVZFMail'
import GenerateVZF from 'graphql/queries/GenerateVZF'
import {
    CustomizeEntry,
    Mutation,
    MutationSaveVzfArgs,
    MutationSendVzfMailArgs,
    Query,
    VzfpdfData,
} from 'graphql/types'
import { Dispatch, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ContactDataActions from 'store/ContactData/ContactData.actions'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import { stateToVZF } from 'utils/convertStateToVZF'
import { generateVZFDataForText } from 'utils/generateVZFDataForText'
import getEnvConfig from 'utils/getEnvConfig'
import { mailIsValid } from 'utils/testable/functions'
import useURLParams from 'utils/URLParamsContex'
export enum SendVZFMailState {
    READY,
    SENDING,
    SENT,
}

export interface VZFState {
    errorState: string
    sendVZFMailState: SendVZFMailState
    email: string
    mailValid: boolean
    currentView: ViewType
    vzfID: string
    vzfSummaryData: VzfpdfData
    B2B: boolean
    onVZFEmailSend: () => void
    onDownloadVZFPDF: () => void
    setEmail: (payload: string) => void
    footNoteText: CustomizeEntry[]
    introText: string[]
}
export const useVZFView = (): VZFState => {
    const { t } = useTranslation()
    const envConfig = getEnvConfig()
    const dispatch = useDispatch<Dispatch<GeneralStateActions | ContactDataActions>>()
    const { appState } = useSelector((appState: AppState) => {
        return {
            appState: appState,
        }
    })
    const footNoteText = appState.generalState.customizeJsData?.vzfConfiguration.footNoteText ?? []
    const introText = appState.generalState.customizeJsData?.vzfConfiguration.introText ?? []
    const { B2B } = useURLParams()
    const [sendVZFMailState, setSendVZFMailState] = useState<SendVZFMailState>(SendVZFMailState.READY)
    const [sendVZFMailCounter, setSendVZFMailCounter] = useState(1)
    const vzfSummaryData = generateVZFDataForText(appState, t, B2B, true, appState.generalState.customizeJsData)
    const [errorState, setErrorState] = useState('')
    const setVZFMail = useCallback(
        (payload: string): void => {
            dispatch({ type: GeneralStateAction.SET_VFZMAIL, payload })
        },
        [dispatch],
    )

    const [sendVZFMail] = useMutation<Mutation>(SendVZFMail, {
        onCompleted: (data) => {
            if (data.sendVZFMail && data.sendVZFMail.status !== 'success') {
                setErrorState('send_email')
            }

            const timeout = sendVZFMailCounter * 5000
            setSendVZFMailState(SendVZFMailState.SENT)
            setTimeout(() => {
                setSendVZFMailState(SendVZFMailState.READY)
                setVZFMail('')
            }, timeout)
            setSendVZFMailCounter(sendVZFMailCounter + 1)
        },
    })
    const [saveVzf] = useMutation<Mutation>(SaveVZF)

    const [generateVZFID] = useLazyQuery<Query>(GenerateVZF, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.generateVZFID) {
                dispatch({ type: GeneralStateAction.SET_GENERAL_STATE_PARTIAL, payload: { vzfID: data.generateVZFID } })
            }
        },
    })

    const onSaveVZF = (): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            const vzfData = stateToVZF(appState)
            const variables: MutationSaveVzfArgs = {
                vzfID: appState.generalState.vzfID,
                input: {
                    additionalInfo: vzfData.additionalInfo,
                    city: vzfData.city,
                    houseNumber: vzfData.houseNumber,
                    startOfMarketing: appState.generalState.startOfMarketing,
                    startOfDelivery: appState.generalState.startOfDelivery,
                    selectedProductCategories: vzfData.selectedProductCategories,
                    multipleSelectOptionList: vzfData.multipleSelectOptionList,
                    street: vzfData.street,
                    zipcode: vzfData.zipcode,
                    email: vzfData.email,
                    path: '',
                    customizeType: vzfData.customizeType,
                },
                pdfData: vzfSummaryData,
                b2b: B2B,
            }

            if (
                appState.generalState.customizeJsData &&
                appState.generalState.clientData &&
                appState.generalState.customizeJsData.globalConfiguration.enableLoadingClient
            ) {
                variables.clientID = appState.generalState.clientData.clientID
            }
            const test = await saveVzf({ variables })
            if (test.data && test.data.saveVZF.status === 'success') {
                resolve(true)
            } else {
                reject(false)
            }
        })
    }

    const onDownloadVZFPDF = (): void => {
        onSaveVZF().then(() => {
            const serverURI =
                process.env.NODE_ENV !== 'production' ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/` : '/'
            fetch(
                serverURI +
                    'vzfdf/' +
                    appState.generalState.vzfID +
                    '/Vertragszusammenfassung_' +
                    appState.generalState.vzfID +
                    '.pdf',
                {
                    method: 'GET',
                },
            )
                .then((e) => {
                    if (e.status === 200) {
                        e.blob().then((blob) => {
                            const a = document.createElement('a')
                            a.href = URL.createObjectURL(blob)
                            a.setAttribute('type', 'hidden')
                            a.target = '_blank'
                            a.download = 'Vertragszusammenfassung_' + appState.generalState.vzfID + '.pdf'
                            document.body.appendChild(a)
                            a.click()
                        })
                    }
                })
                .catch(() => {
                    setErrorState('download_vzf')
                })
        })
    }

    const onVZFEmailSend = (): void => {
        onSaveVZF()
            .then(() => {
                const vzfData = stateToVZF(appState)
                const variables: MutationSendVzfMailArgs = {
                    vzfID: appState.generalState.vzfID,
                    email: vzfData.email,
                }
                if (
                    appState.generalState.customizeJsData &&
                    appState.generalState.clientData &&
                    appState.generalState.customizeJsData.globalConfiguration.enableLoadingClient
                ) {
                    variables.clientID = appState.generalState.clientData?.clientID
                }
                setErrorState('')
                setSendVZFMailState(SendVZFMailState.SENDING)
                sendVZFMail({ variables })
            })
            .catch(() => {
                setErrorState('send_email')
            })
    }

    useEffect(() => {
        if (appState.generalState.vzfID === '') {
            generateVZFID()
        }
    }, [])

    const mailValid = mailIsValid(appState.generalState.vzfMail)

    return {
        errorState,
        sendVZFMailState,
        email: appState.generalState.vzfMail,
        mailValid,
        currentView: appState.generalState.currentView,
        vzfID: appState.generalState.vzfID,
        onVZFEmailSend,
        setEmail: setVZFMail,
        onDownloadVZFPDF,
        vzfSummaryData,
        B2B,
        footNoteText,
        introText,
    }
}
