import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'

const NoMatch = (): JSX.Element => {
    const { t } = useTranslation()
    return (
        <Box
            bgcolor={colors.white}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            height={'calc(100vh - 162px)'}
        >
            <Box display={'flex'} flexDirection={'column'} pt={'calc(50vh - 162px)'} alignItems={'center'}>
                <Typography variant={'h1'}>{'404'}</Typography>
                <Typography variant={'h1'}>{t('noPageFound')}</Typography>
            </Box>
        </Box>
    )
}

export default NoMatch
