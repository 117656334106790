import { Customize } from 'graphql/types'
import React, { createContext, useContext, useState } from 'react'

export interface UserInfo {
    number: string
    name: string
    roles: string[]
}

const initialUserInfo: UserInfo | null = null

const useUserInfoValue = () => useState<UserInfo | null>(initialUserInfo)

const UserInfoContext = createContext<ReturnType<typeof useUserInfoValue> | null>(null)

export const useUserInfo = (): [UserInfo | null, React.Dispatch<React.SetStateAction<UserInfo | null>>] => {
    const value = useContext(UserInfoContext)
    if (value === null) throw new Error('Please add UserInfoProvider')

    return value
}

type UserInfoProviderProps = {
    children?: React.ReactNode
}

export const UserInfoProvider: React.FC = (props: UserInfoProviderProps) => (
    <UserInfoContext.Provider value={useUserInfoValue()}>{props.children}</UserInfoContext.Provider>
)

export const isSalesPartner = (roles: string[], customizeJsData?: Customize): boolean =>
    customizeJsData !== undefined &&
    !customizeJsData.globalConfiguration.enableLoadingClient &&
    roles.includes('Vertriebspartner')

export const getRoleAwareTranslationKey = (
    userInfo: UserInfo | null,
    customizeJsData: Customize | undefined,
    key: string,
): string => {
    if (userInfo && isSalesPartner(userInfo.roles, customizeJsData)) {
        return key + 'SalesPartner'
    } else {
        return key
    }
}

export const mockUserInfo: UserInfo = { name: '', roles: ['Vertriebspartner'], number: '' }
