import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import { cloneDeep } from 'lodash'
// import ProductCategoriesQuery from 'graphql/queries/ProductCategories'
import {
    ConfigurationDisplayType,
    ConfigurationType,
    Customize,
    OptionConfiguration,
    TelephoneConfiguration,
} from 'graphql/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import GeneralStateActions, { ConfigPayload, GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import { AppState } from 'store/store'

interface TelephoneConfigurationViewReducerReturn {
    currentView: ViewType
    customizeJsData: Customize | undefined
    configurationMapState: Map<string, string | string[]>
    disabledSubmit: boolean
    setConfigurationMapRadioState: (payload: ConfigPayload) => void
    setConfigurationMapCheckState: (payload: ConfigPayload) => void
    convertOptionToOptionItem: (
        option: OptionConfiguration,
        displayType: ConfigurationDisplayType,
        optionsLength: number,
    ) => ConfigurationItemOption
    resetPortabilityState: () => void
}

export const useTelephoneConfigurationViewReducer: () => TelephoneConfigurationViewReducerReturn = () => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions | PortabilityStateActions>>()

    const [requiredTelephoneField, setRequiredTelephoneField] = useState<string[]>([])
    const { currentView, customizeJsData, configurationMapState, providerOptions } = useSelector(
        (appState: AppState) => {
            return {
                configurationMapState: appState.generalState.configuration,
                currentView: appState.generalState.currentView,
                customizeJsData: appState.generalState.customizeJsData,
                providerOptions: appState.portabilityState.providerOptions,
            }
        },
    )

    const setConfigurationMapRadioState = useCallback(
        (payload: ConfigPayload) => {
            dispatch({ type: GeneralStateAction.SET_CONFIG_VALUE, payload })
        },
        [dispatch],
    )

    const setConfigurationMapCheckState = useCallback(
        (payload: ConfigPayload) => {
            dispatch({ type: GeneralStateAction.TOGGLE_CONFIG_VALUE, payload })
        },
        [dispatch],
    )

    const resetPortabilityState = useCallback(() => {
        const copy = cloneDeep(providerOptions)
        dispatch({ type: PortabilityStateAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: PortabilityStateAction.SET_PROVIDER_OPTIONS, payload: copy })
    }, [dispatch])

    const disabledSubmit = useMemo((): boolean => {
        if (requiredTelephoneField.length > 0) {
            for (let i = 0; i < requiredTelephoneField.length; i++) {
                const item = configurationMapState.get(requiredTelephoneField[i])
                if (item === undefined) {
                    return true
                }
            }
        }
        return false
    }, [configurationMapState, requiredTelephoneField])

    const convertOptionToOptionItem = (
        option: OptionConfiguration,
        displayType: ConfigurationDisplayType,
        optionsLength: number,
    ): ConfigurationItemOption => {
        // @ts-expect-error: should return  the same value
        const newColumnValue: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined =
            optionsLength > 0 && Math.floor(12 / optionsLength)
        switch (displayType) {
            case ConfigurationDisplayType.FREE:
                return {
                    columnValue: 'auto',
                    label: option.labelKey,
                    mobileColumnValue: 12,
                    value: option.value,
                }
            case ConfigurationDisplayType.MULTI_LINE:
                return {
                    columnValue: 12,
                    label: option.labelKey,
                    mobileColumnValue: 12,
                    value: option.value,
                }
            case ConfigurationDisplayType.ONE_LINE:
                return {
                    columnValue: newColumnValue,
                    label: option.labelKey,
                    mobileColumnValue: 12,
                    value: option.value,
                }
        }
    }
    // generate on init config map for required fields
    useEffect(() => {
        if (customizeJsData) {
            const requiredFields: string[] = []
            customizeJsData.telephoneOptions.telephoneConfiguration.forEach(
                (telephoneConfiguration: TelephoneConfiguration) => {
                    if (telephoneConfiguration.required === true) {
                        requiredFields.push(telephoneConfiguration.identifier)
                    }
                    if (telephoneConfiguration.type === ConfigurationType.CHECKBOXES) {
                        if (telephoneConfiguration.options) {
                            telephoneConfiguration.options.forEach((option: OptionConfiguration) => {
                                if (option.preselected) {
                                    const valueInMap = configurationMapState.get(telephoneConfiguration.identifier)
                                    if (
                                        (valueInMap && !valueInMap.includes(option.value)) ||
                                        valueInMap === undefined
                                    ) {
                                        setConfigurationMapCheckState({
                                            identifier: telephoneConfiguration.identifier,
                                            value: option.value,
                                        })
                                    }
                                }
                            })
                        }
                    }
                    if (telephoneConfiguration.type === ConfigurationType.RADIO_BUTTONS) {
                        if (telephoneConfiguration.options) {
                            telephoneConfiguration.options.forEach((option: OptionConfiguration) => {
                                if (option.preselected) {
                                    const valueInMap = configurationMapState.get(telephoneConfiguration.identifier)
                                    if (
                                        (valueInMap &&
                                            !valueInMap.includes(option.value) &&
                                            valueInMap === option.value) ||
                                        valueInMap === undefined
                                    ) {
                                        setConfigurationMapRadioState({
                                            identifier: telephoneConfiguration.identifier,
                                            value: option.value,
                                        })
                                    }
                                }
                            })
                        }
                    }
                },
            )
            setRequiredTelephoneField(requiredFields)
        }
    }, [])

    // check requiredFields on userAction
    useEffect(() => {
        if (customizeJsData) {
            const requiredFields: string[] = []
            customizeJsData.telephoneOptions.telephoneConfiguration.forEach((v) => {
                if (v.required === true) {
                    requiredFields.push(v.identifier)
                }
                if (v.type === ConfigurationType.CONDITIONAL && v.render) {
                    if (v.condition) {
                        const conditionIsGiven = configurationMapState.get(v.condition.identifier)
                        // check children if condition isIn Map
                        if (conditionIsGiven !== undefined) {
                            v.render.forEach((children) => {
                                if (children.required === true) {
                                    requiredFields.push(children.identifier)
                                }
                                if (!configurationMapState.has(children.identifier)) {
                                    children.options?.forEach((option: OptionConfiguration) => {
                                        if (option.preselected) {
                                            const valueInMap = configurationMapState.get(children.identifier)
                                            if (
                                                (valueInMap && !valueInMap.includes(option.value)) ||
                                                valueInMap === undefined
                                            ) {
                                                if (children.type === ConfigurationType.RADIO_BUTTONS) {
                                                    setConfigurationMapRadioState({
                                                        identifier: children.identifier,
                                                        value: option.value,
                                                    })
                                                } else {
                                                    setConfigurationMapCheckState({
                                                        identifier: children.identifier,
                                                        value: option.value,
                                                    })
                                                }
                                            }
                                        }
                                    })
                                }
                            })
                        } else {
                            // remove children items out of configurationMapState
                            v.render.forEach((remove) => {
                                configurationMapState.delete(remove.identifier)
                            })
                        }
                    }
                }
            })
            setRequiredTelephoneField(requiredFields)
        }
    }, [configurationMapState])

    return {
        configurationMapState,
        convertOptionToOptionItem,
        currentView,
        customizeJsData,
        disabledSubmit,
        setConfigurationMapCheckState,
        resetPortabilityState,
        setConfigurationMapRadioState,
    }
}
