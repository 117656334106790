import { Box, Grid, Link, Typography } from '@material-ui/core'
import { FooterLink } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'
import colors from 'theme/colors'

const Footer = (): JSX.Element => {
    const { customize, clientData } = useSelector((state: AppState) => ({
        customize: state.generalState.customizeJsData,
        clientData: state.generalState.clientData,
    }))
    const { t } = useTranslation()

    const hiddenVersion = require('./hiddenVersion.json')

    let footerLinks: FooterLink[] = []
    if (customize && customize.globalConfiguration) {
        footerLinks = JSON.parse(JSON.stringify(customize.globalConfiguration.footer))
        if (customize.globalConfiguration.enableLoadingClient && clientData) {
            footerLinks.splice(0, 0, {
                i18Key: 'agbClient',
                link: clientData.linkAGB,
            })
        }
    }

    return (
        <Box bgcolor={colors.green} pt={2} pb={2} minHeight={{ xs: 80, sm: 40 }}>
            <Grid container>
                <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={'center'}
                    direction={'row'}
                    justifyContent={'center'}
                    className={'FooterIconWrapper'}
                >
                    {customize && !customize.globalConfiguration.enableLoadingClient && (
                        <Box textAlign={{ xs: 'center', sm: 'left' }} marginBottom={{ xs: 2, md: 0 }}>
                            <img
                                src={
                                    process.env.PUBLIC_URL + '/customize/' + customize?.globalConfiguration.icons.footer
                                }
                                alt={'logo'}
                                className={'FooterIcon'}
                            />
                        </Box>
                    )}
                </Grid>
                <Box flex={1}>
                    <Grid container item xs={12} lg={9} xl={7} className={'FooterLinkWrapper'}>
                        {customize &&
                            footerLinks.map((footerLink, index) => (
                                <Box
                                    key={index}
                                    marginRight={index !== customize.globalConfiguration.footer.length ? 2 : 0}
                                    alignSelf={'center'}
                                >
                                    {footerLink.link !== '' && (
                                        <Link
                                            target="_blank"
                                            className={'FooterLink'}
                                            href={footerLink.link}
                                            color={'secondary'}
                                        >
                                            {t(footerLink.i18Key)}
                                        </Link>
                                    )}
                                    {footerLink.link === '' && (
                                        <Typography className={'FooterLink'} color={'secondary'} variant={'h5'}>
                                            {t(footerLink.i18Key)}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                        <Typography
                            className={'FooterLink'}
                            color={'primary'}
                            variant={'subtitle2'}
                            style={{ position: 'absolute', right: 0, bottom: 0 }}
                        >
                            Version:{hiddenVersion.version} Iteration:{hiddenVersion.iteration}
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}

export default Footer
