import { Box, Button, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import TestIDs from 'utils/TestIDs'
import ArrowIcon from './ArrowIcon'

export interface OfferOptionProps {
    id: number
    offerText?: string
    onSelect: (id: number) => void
    selected: boolean
    title: string
}

const OfferOption = (props: OfferOptionProps): JSX.Element => {
    const { t } = useTranslation()
    const { title, id, offerText, onSelect, selected } = props
    const splittedTitle = title.split(' ')

    return (
        <Button
            className={clsx('FormButton', 'OfferOptionButton', { Active: selected })}
            onClick={(): void => onSelect(id)}
            style={{ position: 'relative' }}
            {...TestIDs.GET_PRODUCT_SELECTION('SWITCH_MONTH', splittedTitle[0])}
        >
            {offerText !== undefined && (
                <Box pb={'60px'} position={'absolute'}>
                    <Typography variant={'subtitle1'} className={'SwitchMonthOffer'}>
                        {offerText}
                    </Typography>
                    <Box component={'span'} marginBottom={'-5px'} zIndex={10}>
                        <ArrowIcon width={'26px'} height={'14px'} color={colors.white} />
                    </Box>
                </Box>
            )}
            {`${splittedTitle[0]} ${t(splittedTitle[1])}`}
        </Button>
    )
}

export default OfferOption
