import { Grid } from '@material-ui/core'
import PostCodeAddress from 'components/PostCodeAddress/PostCodeAddress'
import { Customize, PostCodeDetail } from 'graphql/types'
import { useEffect } from 'react'
import { PortabilityAddress, PortabilityViewFields } from 'store/PortabilityState/PortabilityState.reducer'
import RenderRadios from './RenderRadios'

export interface RenderAddressProps {
    address: PortabilityAddress
    onChange: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    selected: boolean
    customizeJsData: Customize | undefined
    handleAddressSelected: (data: PostCodeDetail) => void
}
const RenderAddress = (props: RenderAddressProps): JSX.Element => {
    const { address, onChange, selected, handleAddressSelected, customizeJsData } = props
    const field = PortabilityViewFields.ADDRESS

    useEffect(() => {
        if (selected) {
            handleAddressSelected({
                address: {
                    street: '',
                    postcode: '',
                    buildingNumber: -1,
                    locality: '',
                    building: '',
                    country: '',
                    buildingNumberAddition: '',
                },
                mailLines: [],
                location: {
                    latitude: 0,
                    longitude: 0,
                    precision: '',
                },
                language: '',
                country: {
                    name: '',
                    iso3Code: '',
                },
            })
        }
    }, [selected])

    return (
        <>
            <Grid container spacing={1} style={{ marginBottom: '15px', marginTop: '45px', flexDirection: 'column' }}>
                <RenderRadios field={field} customizeJsData={customizeJsData} onChange={onChange} selected={selected} />
                {!selected && (
                    <PostCodeAddress
                        data={{
                            street: address.street,
                            houseNumber: address.houseNumber,
                            city: address.city,
                            zipcode: address.zip,
                        }}
                        handleAddressSelect={handleAddressSelected}
                    />
                )}
            </Grid>
        </>
    )
}

export default RenderAddress
