import { Customize } from 'graphql/types'
import { InstallationDetailsFields } from 'pages/InstallationDetails/useInstallationDetailsReducer'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import { ContactDataState } from 'store/ContactData/ContactData.reducer'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import { URLParams } from 'utils/URLParamsContex'
import { salutationIsCompany } from 'utils/testable/functions'
import ContactDataActions, { ContactDataAction } from '../../store/ContactData/ContactData.actions'
import { Error, InstallationDetailsState, ViewType } from '../../store/GeneralState/GeneralState.reducer'
import { isPhoneNumber } from '../../utils/testable/checkPhoneNumber'

interface contactDataPageState {
    b2b: boolean
    currentView: ViewType
    errors: Error[]
    contactData: ContactDataState
    availability: AvailabilityCheckState
    inLineErrors: string[]
    areThereAnyMissingFields: string[]
    setContactDataPartial: (payload: Partial<ContactDataState>) => void
    customizeJsData: Customize | undefined
    submitButtonTxt: string | undefined
    displayDeliveryAddress: boolean
    installationDetails: InstallationDetailsState
    onChangeInstallationFields: (field: InstallationDetailsFields, value: string | number) => void
}

export const useContactDataReducer: () => contactDataPageState = () => {
    const dispatch = useDispatch<Dispatch<ContactDataActions | GeneralStateActions>>()
    const { t } = useTranslation()

    const {
        currentView,
        errors,
        inLineErrors,
        availability,
        contactData,
        customizeJsData,
        pagesList,
        selectedProductCategories,
        availableProductCategories,
        installationDetails,
    } = useSelector((appState: AppState) => {
        return {
            currentView: appState.generalState.currentView,
            errors: appState.generalState.errors,
            inLineErrors: appState.generalState.inLineErrors,
            availability: appState.availabilityCheck,
            contactData: appState.contactData,
            customizeJsData: appState.generalState.customizeJsData,
            pagesList: appState.generalState.pagesList,
            selectedProductCategories: appState.generalState.selectedProductCategories,
            availableProductCategories: appState.generalState.availableProductCategories,
            installationDetails: appState.generalState.installationDetails,
        }
    })

    let displayDeliveryAddress = true
    if (customizeJsData && customizeJsData.contactDataViewConfiguration.deliveryAddressConfiguration.enabled) {
        if (
            customizeJsData &&
            customizeJsData.contactDataViewConfiguration.deliveryAddressConfiguration.enabledDetectOwnHardware
        ) {
            displayDeliveryAddress = false
            selectedProductCategories.map((currentProcutCategory) => {
                const selectedProductCategory = availableProductCategories.find(
                    (pc) => pc.id === currentProcutCategory.id,
                )
                if (currentProcutCategory.selectedProduct) {
                    const selectedProduct = selectedProductCategory?.products.find(
                        (p) => p.id === currentProcutCategory.selectedProduct?.id,
                    )
                    for (const currentProductType of currentProcutCategory.selectedProduct.productTypes) {
                        const selectedProductType = selectedProduct?.productTypes.find(
                            (pt) => pt.id === currentProductType.id,
                        )
                        for (const currentOptionCategory of currentProductType.optionCategories) {
                            const selectedOptionCategory = selectedProductType?.category.find(
                                (c) => c.id === currentOptionCategory.id,
                            )
                            if (selectedOptionCategory?.type == 'hardware') {
                                for (const options of currentOptionCategory.selectedOptions) {
                                    if (
                                        !customizeJsData.contactDataViewConfiguration.deliveryAddressConfiguration.ownHardwareID.includes(
                                            options,
                                        )
                                    ) {
                                        displayDeliveryAddress = true
                                    }
                                }
                            }
                        }
                    }
                }
            })
        }
    } else {
        displayDeliveryAddress = false
    }

    const setContactDataPartial = useCallback(
        (payload: Partial<ContactDataState>) => {
            dispatch({ type: ContactDataAction.SET_CONTACT_STATE_PARTIAL, payload })
        },
        [dispatch],
    )

    const setInstallationDetailsState = useCallback(
        (payload: InstallationDetailsState) => {
            dispatch({ type: GeneralStateAction.SET_INSTALLATION_DETAILS, payload })
        },
        [dispatch],
    )

    const onChangeInstallationFields = (field: InstallationDetailsFields, value: string | number): void => {
        const newInstallationDetailsState = { ...installationDetails }
        switch (field) {
            case InstallationDetailsFields.FLAT_NUMBER:
                newInstallationDetailsState.flatNumber = String(value)
                break
            case InstallationDetailsFields.FLAT_POSITION:
                newInstallationDetailsState.flatPosition = String(value)
                break
            case InstallationDetailsFields.FLOOR_INFO:
                newInstallationDetailsState.floorInformation = String(value)
                break
        }
        setInstallationDetailsState(newInstallationDetailsState)
    }

    const b2b = URLParams().B2B
    let submitButtonTxt = undefined

    if (pagesList.findIndex((p) => p.path === '/FestnetzVertragsOptionen') !== -1) {
        submitButtonTxt = t(currentView + 'ButtonText2')
    }

    const [areThereAnyMissingFields] = useMemo(() => {
        const missingFields = [
            'personalSalutation',
            'personalName',
            'personalLastName',
            'personalEmail',
            'personalBirthDate',
            'personalTelephone',
            'personalMobilePhone',
            'deliverySalutation',
            'deliveryName',
            'deliveryLastName',
            'deliveryStreet',
            'deliveryHouseNumber',
            'deliveryZip',
            'deliveryCity',
            'billingSalutation',
            'billingName',
            'billingLastName',
            'billingStreet',
            'billingHouseNumber',
            'billingZip',
            'billingCity',
        ]
        const newErrors: string[] = [...inLineErrors]

        if (contactData.personalSalutation !== '') {
            const index = missingFields.indexOf('personalSalutation')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (contactData.personalName !== '') {
            const index = missingFields.indexOf('personalName')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (contactData.personalLastName !== '') {
            const index = missingFields.indexOf('personalLastName')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (contactData.personalEmail !== '') {
            const index = missingFields.indexOf('personalEmail')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (contactData.personalBirthDate !== '' && contactData.personalBirthDate !== '__.__.____') {
            const index = missingFields.indexOf('personalBirthDate')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (contactData.personalTelephone !== '') {
            if (isPhoneNumber(contactData.personalTelephone)) {
                const index = missingFields.indexOf('personalTelephone')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        } else {
            const index = missingFields.indexOf('personalTelephone')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (contactData.personalMobilePhone !== '') {
            if (isPhoneNumber(contactData.personalMobilePhone)) {
                const index = missingFields.indexOf('personalMobilePhone')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        } else {
            const index = missingFields.indexOf('personalMobilePhone')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
        }
        if (contactData.deviatingDeliveryAddress === false) {
            const index = missingFields.indexOf('deliverySalutation')
            if (index !== -1) {
                missingFields.splice(index, 7)
            }
        } else {
            if (contactData.deliverySalutation !== '') {
                const index = missingFields.indexOf('deliverySalutation')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.deliveryName !== '') {
                const index = missingFields.indexOf('deliveryName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.deliveryLastName !== '') {
                const index = missingFields.indexOf('deliveryLastName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.deliveryZip !== '') {
                if (contactData.deliveryZip.length === 5) {
                    const index = missingFields.indexOf('deliveryZip')
                    if (index !== -1) {
                        missingFields.splice(index, 1)
                    }
                }
            }
            if (contactData.deliveryCity !== '') {
                const index = missingFields.indexOf('deliveryCity')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.deliveryStreet !== '') {
                const index = missingFields.indexOf('deliveryStreet')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.deliveryHouseNumber !== '') {
                const index = missingFields.indexOf('deliveryHouseNumber')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        }
        if (contactData.deviatingBillingAddress === false) {
            const index = missingFields.indexOf('billingSalutation')
            if (index !== -1) {
                missingFields.splice(index)
            }
        } else {
            if (contactData.billingSalutation !== '') {
                const index = missingFields.indexOf('billingSalutation')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.billingName !== '') {
                const index = missingFields.indexOf('billingName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.billingLastName !== '') {
                const index = missingFields.indexOf('billingLastName')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.billingZip !== '') {
                if (contactData.billingZip.length === 5) {
                    const index = missingFields.indexOf('billingZip')
                    if (index !== -1) {
                        missingFields.splice(index, 1)
                    }
                }
            }
            if (contactData.billingCity !== '') {
                const index = missingFields.indexOf('billingCity')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.billingStreet !== '') {
                const index = missingFields.indexOf('billingStreet')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
            if (contactData.billingHouseNumber !== '') {
                const index = missingFields.indexOf('billingHouseNumber')
                if (index !== -1) {
                    missingFields.splice(index, 1)
                }
            }
        }

        // check B2B MODE ON
        if (b2b === true) {
            if (contactData.company === '') {
                missingFields.push('company')
            }

            if (contactData.deviatingBillingAddress && contactData.billingCompany === '') {
                missingFields.push('billingCompany')
            }

            if (contactData.deviatingDeliveryAddress && contactData.deliveryCompany === '') {
                missingFields.push('deliveryCompany')
            }

            if (customizeJsData && customizeJsData.contactDataViewConfiguration) {
                if (
                    contactData.companyLocation === '' &&
                    customizeJsData.contactDataViewConfiguration.enableCompanyLocation
                ) {
                    missingFields.push('companyLocation')
                }

                if (
                    contactData.companyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableLegalForm
                ) {
                    missingFields.push('companyLegalForm')
                }

                if (contactData.companyId === '' && customizeJsData.contactDataViewConfiguration.enableCompanyTaxID) {
                    missingFields.push('companyTaxId')
                }

                if (
                    contactData.deviatingBillingAddress &&
                    contactData.billingCompanyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    missingFields.push('billingCompanyLegalForm')
                }

                if (
                    contactData.deviatingDeliveryAddress &&
                    contactData.deliveryCompanyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    missingFields.push('deliveryCompanyLegalForm')
                }
            }
        } else {
            // salutation === company but b2b === false
            if (salutationIsCompany(contactData.personalSalutation) && contactData.company === '') {
                missingFields.push('company')
            }

            if (
                salutationIsCompany(contactData.billingSalutation) &&
                contactData.deviatingBillingAddress &&
                contactData.billingCompany === ''
            ) {
                missingFields.push('billingCompany')
            }

            if (
                salutationIsCompany(contactData.deliverySalutation) &&
                contactData.deviatingDeliveryAddress &&
                contactData.deliveryCompany === ''
            ) {
                missingFields.push('deliveryCompany')
            }

            if (customizeJsData && customizeJsData.contactDataViewConfiguration) {
                if (
                    salutationIsCompany(contactData.billingSalutation) &&
                    contactData.deviatingBillingAddress &&
                    contactData.billingCompanyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    missingFields.push('billingCompanyLegalForm')
                }

                if (
                    salutationIsCompany(contactData.deliverySalutation) &&
                    contactData.deviatingDeliveryAddress &&
                    contactData.deliveryCompanyLegalForm === '' &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    missingFields.push('deliveryCompanyLegalForm')
                }
            }
        }
        if (customizeJsData && customizeJsData.installationDetailsConfiguration.displayInContactPage) {
            if (
                customizeJsData.installationDetailsConfiguration.appartmentData?.flatNumberRequired === true &&
                installationDetails.flatNumber === ''
            ) {
                missingFields.push('flatNumber')
            }
            if (
                customizeJsData.installationDetailsConfiguration.appartmentData?.flatPositionRequired === true &&
                installationDetails.flatPosition === ''
            ) {
                missingFields.push('flatPosition')
            }
            if (
                customizeJsData.installationDetailsConfiguration.appartmentData?.floorInformationRequired === true &&
                installationDetails.floorInformation === ''
            ) {
                missingFields.push('floorInformation')
            }
        }
        if (customizeJsData && customizeJsData.contactDataViewConfiguration.existingCustomerForm === true) {
            const existingCustomerIndex = missingFields.indexOf('existingCustomer')

            if (contactData.existingCustomerRadio === '') {
                if (existingCustomerIndex === -1) {
                    missingFields.push('existingCustomer')
                }
            }

            if (contactData.existingCustomerRadio === 'no' || contactData.existingCustomerNumber !== '') {
                if (existingCustomerIndex !== -1) {
                    missingFields.splice(existingCustomerIndex, 1)
                }
            } else if (contactData.existingCustomerRadio === 'yes' && contactData.existingCustomerNumber === '') {
                if (existingCustomerIndex === -1) {
                    missingFields.push('existingCustomer')
                }
            }
        }

        return [missingFields, newErrors]
    }, [contactData, installationDetails])

    const setSalutationToCompany = useRef(false)
    if (b2b && !setSalutationToCompany.current) {
        setContactDataPartial({ personalSalutation: 'Firma' })
        setSalutationToCompany.current = true
    }

    return {
        b2b,
        currentView,
        errors,
        contactData,
        availability,
        areThereAnyMissingFields,
        inLineErrors,
        setContactDataPartial,
        customizeJsData,
        submitButtonTxt,
        displayDeliveryAddress,
        installationDetails,
        onChangeInstallationFields,
    }
}
