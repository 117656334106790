import { Grid, Link, Typography } from '@material-ui/core'
import ConfigurationItem from 'components/ConfigurationItem'
import { Customize } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'
import { PortabilityViewFields } from 'store/PortabilityState/PortabilityState.reducer'
import TestIDs from 'utils/TestIDs'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'

export interface RenderRadiosProps {
    field: PortabilityViewFields
    link?: string
    onChange: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    selected: boolean
    customizeJsData: Customize | undefined
}
const RenderRadios = (props: RenderRadiosProps): JSX.Element => {
    const { field, link, onChange, customizeJsData, selected } = props
    const { t } = useTranslation()

    const [userInfo] = useUserInfo()

    return (
        <>
            <Grid item xs={12}>
                <Typography {...TestIDs.GET_PORTABILITY_DETAILS('INPUT', field + '_TITLE')} variant={'h4'}>
                    {t('portabilityDetailsStrings.' + field + '.Label')}
                </Typography>
                {link && (
                    <Link
                        href={link}
                        target={'_blank'}
                        {...TestIDs.GET_PORTABILITY_DETAILS('INPUT', field + '_LINK')}
                        className={'ConfigurationLink'}
                    >
                        {t('portabilityDetailsStrings.' + field + '.Link')}
                    </Link>
                )}
            </Grid>
            <Grid item xs={12}>
                <ConfigurationItem
                    selected={selected}
                    paddingRight={'0'}
                    option={{
                        columnValue: 12,
                        label: t(
                            getRoleAwareTranslationKey(
                                userInfo,
                                customizeJsData,
                                'portabilityDetailsStrings.' + field + '.Yes',
                            ),
                        ),
                        mobileColumnValue: 12,
                        value: field + 'Yes',
                    }}
                    type={OptionType.RADIO}
                    onSelect={(): void => onChange(field, 'RADIO', -1, field + 'Yes')}
                />
            </Grid>
            <Grid item xs={12}>
                <ConfigurationItem
                    selected={!selected}
                    paddingRight={'0'}
                    option={{
                        columnValue: 12,
                        label: t('portabilityDetailsStrings.' + field + '.No'),
                        mobileColumnValue: 12,
                        value: field + 'No',
                    }}
                    type={OptionType.RADIO}
                    onSelect={(): void => onChange(field, 'RADIO', -1, field + 'No')}
                />
            </Grid>
        </>
    )
}

export default RenderRadios
