import { Customize, Option, OptionCategory, ProductCategory, ProductType, SelectedOptionCategory } from 'graphql/types'

const getProductTypeData = (
    availableProducts: ProductCategory[],
    productId: string,
    productTypeId: string,
): ProductType | null => {
    for (const productCategory of availableProducts) {
        for (const product of productCategory.products) {
            if (product.id === productId) {
                for (const productType of product.productTypes) {
                    if (productType.id === productTypeId) {
                        return productType
                    }
                }
            }
        }
    }
    return null
}

const getOptionData = (optionCategories: OptionCategory[], optionId: string): Option | null => {
    for (const optionCategory of optionCategories) {
        for (const option of optionCategory.options) {
            if (option.id === optionId) {
                return option
            }
        }
    }

    return null
}

const getRequiredOptionCategory = (optionCategories: OptionCategory[], optionId: string): OptionCategory | null => {
    for (const optionCategory of optionCategories) {
        for (const option of optionCategory.options) {
            if (option.id === optionId) {
                return optionCategory
            }
        }
    }

    return null
}

export const resolveRequiredOption = (
    availableProducts: ProductCategory[],
    productId: string,
    productTypeId: string,
    optionId: string,
    selectedOptionCategory: SelectedOptionCategory[],
): SelectedOptionCategory[] => {
    const productType = getProductTypeData(availableProducts, productId, productTypeId)
    if (!productType) {
        return selectedOptionCategory
    }
    const option = getOptionData(productType.category, optionId)
    if (!option) {
        return selectedOptionCategory
    }

    for (const requiredArticle of option.requiredArticles) {
        let found = false
        const requiredOptionCategory = getRequiredOptionCategory(productType.category, requiredArticle)
        if (!requiredOptionCategory) {
            continue
        }

        for (const optionCategory of selectedOptionCategory) {
            if (optionCategory.id !== requiredOptionCategory.id) {
                continue
            }
            for (const option of optionCategory.selectedOptions) {
                if (option === requiredArticle) {
                    found = true
                    break
                }
            }
            if (!found) {
                found = false
                optionCategory.selectedOptions.push(requiredArticle)
            }
        }
    }

    return selectedOptionCategory
}

export const resolveExcludedOption = (
    availableProducts: ProductCategory[],
    productId: string,
    productTypeId: string,
    optionId: string,
    selectedOptionCategory: SelectedOptionCategory[],
): SelectedOptionCategory[] => {
    const productType = getProductTypeData(availableProducts, productId, productTypeId)
    if (!productType) {
        return selectedOptionCategory
    }
    const option = getOptionData(productType.category, optionId)
    if (!option) {
        return selectedOptionCategory
    }

    for (const excludedArticle of option.excludedArticles) {
        for (const optionCategory of selectedOptionCategory) {
            const selectedOptionIndex = optionCategory.selectedOptions.findIndex(
                (selectedOption: string) => selectedOption === excludedArticle,
            )
            if (selectedOptionIndex >= 0) {
                optionCategory.selectedOptions.splice(selectedOptionIndex, 1)
            }
        }
    }

    return selectedOptionCategory
}

export const removeDependingOption = (
    availableProducts: ProductCategory[],
    productId: string,
    productTypeId: string,
    optionId: string,
    selectedOptionCategory: SelectedOptionCategory[],
): SelectedOptionCategory[] => {
    for (const optionCategory of selectedOptionCategory) {
        const selectedOptionIndex = optionCategory.selectedOptions.findIndex((selectedOption: string) => {
            const productType = getProductTypeData(availableProducts, productId, productTypeId)
            if (!productType) {
                return false
            }
            const optionData = getOptionData(productType.category, selectedOption)
            if (!optionData || optionData.requiredArticles.length === 0) {
                return false
            }
            if (optionData.requiredArticles.length > 0) {
                for (const requiredArticle of optionData.requiredArticles) {
                    if (requiredArticle === optionId) {
                        return true
                    }
                }
            } else if (optionData.preconditionArticle.length > 0) {
                for (const preconditionArticle of optionData.preconditionArticle) {
                    if (preconditionArticle === optionId) {
                        return true
                    }
                }
            }

            return false
        })
        if (selectedOptionIndex >= 0) {
            optionCategory.selectedOptions.splice(selectedOptionIndex, 1)
        }
    }

    return selectedOptionCategory
}

export const removeHardwareSelection = (
    selectedOptionCategories: SelectedOptionCategory[],
    availableProducts: ProductCategory[],
    productId: string,
    productTypeId: string,
    viewType: string,
    customizeJsData?: Customize,
): SelectedOptionCategory[] => {
    if (!customizeJsData || viewType !== 'service') {
        return selectedOptionCategories
    }

    const productType = getProductTypeData(availableProducts, productId, productTypeId)
    if (!productType) {
        return selectedOptionCategories
    }

    for (const hardwareSelection of customizeJsData.globalConfiguration.enableHardwareSelection) {
        if (hardwareSelection === productType.identifier) {
            let hasNoServiceOptions = true

            const serviceCategories = productType.category.filter((cat) => cat.type === 'service')
            for (const selectedOptionCategory of selectedOptionCategories) {
                if (serviceCategories.find((serv) => serv.id === selectedOptionCategory.id) !== undefined) {
                    hasNoServiceOptions = false
                    break
                }
            }

            if (hasNoServiceOptions) {
                return []
            }
        }
    }

    return selectedOptionCategories
}
