import { useMutation } from '@apollo/client'
import SendTouchPoint from 'graphql/mutations/SendTouchPoint'
import { Client, Customize, Mutation } from 'graphql/types'
import { Dispatch, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AvailabilityCheckActions, { AvailabilityCheckAction } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import BankDetailsActions, { BankDetailsAction } from 'store/BankDetails/BankDetails.actions'
import ContactDataActions, { ContactDataAction } from 'store/ContactData/ContactData.actions'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { LoadState, ViewType } from 'store/GeneralState/GeneralState.reducer'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { isSalesPartner, useUserInfo } from 'utils/UserInfoContext'
import { executeTrackingCall } from 'utils/tracking'

export enum SendTouchPointState {
    READY,
    SENDING,
    SENT,
}

export interface SelectedTouchpoint {
    selected: string
    input: string
}

interface OrderConfirmationReducerReturn {
    loadState: LoadState
    viewType: ViewType
    salesPartner: boolean
    newOrder: () => void
    customizeJsData: Customize | undefined
    clientData: Client | undefined
    selectedTouchPoint: SelectedTouchpoint
    setSelectedTouchPoint: (value: SelectedTouchpoint) => void
    onSubmit: () => void
    errorState: string
    sendTouchPointState: SendTouchPointState
}

export const useOrderConfirmationReducer: () => OrderConfirmationReducerReturn = () => {
    const { B2B } = useURLParams()
    const { t } = useTranslation()
    const { loadState, viewType, customizeJsData, orderID, clientData } = useSelector((appState: AppState) => {
        return {
            loadState: appState.generalState.loadState,
            viewType: appState.generalState.currentView,
            orderID: appState.generalState.orderID,
            customizeJsData: appState.generalState.customizeJsData,
            clientData: appState.generalState.clientData,
        }
    })

    const [selectedTouchPoint, setSelectedTouchPoint] = useState<SelectedTouchpoint>({
        selected: '',
        input: '',
    })
    const [sendTouchPointState, setSendTouchPointState] = useState<SendTouchPointState>(SendTouchPointState.READY)
    const [errorState, setErrorState] = useState<string>('')

    const history = useHistory()

    useLayoutEffect(() => {
        const body = document.querySelector('body')
        if (body) {
            body.scrollTo(0, 0)
        }
        executeTrackingCall('/VielenDank')
    }, [])

    const [userInfo] = useUserInfo()

    const salesPartner = useMemo(() => {
        if (userInfo) {
            return isSalesPartner(userInfo.roles)
        }
        return false
    }, [userInfo])

    const [sendTouchPoint] = useMutation<Mutation>(SendTouchPoint, {
        onCompleted: (data) => {
            if (data.sendTouchPoint && data.sendTouchPoint.status === 'success') {
                setSendTouchPointState(SendTouchPointState.SENT)
            } else {
                setErrorState('sendTouchPointError')
                setSendTouchPointState(SendTouchPointState.READY)
            }
        },
        onError: (error) => {
            setErrorState('sendTouchPointError')
            setSendTouchPointState(SendTouchPointState.READY)
            console.error({ errors: [error.message], errorsTitle: error.name })
        },
    })

    const dispatch =
        useDispatch<
            Dispatch<
                | AvailabilityCheckActions
                | ContactDataActions
                | BankDetailsActions
                | GeneralStateActions
                | PortabilityStateActions
            >
        >()

    const resetState = () => {
        dispatch({ type: AvailabilityCheckAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: ContactDataAction.SET_TO_INIT_STATE })
        dispatch({ type: BankDetailsAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: GeneralStateAction.SET_TO_INIT_STATE, payload: { B2B } })
        dispatch({ type: PortabilityStateAction.SET_TO_INIT_STATE, payload: null })
    }

    const newOrder = (): void => {
        resetState()
        history.push('/')
    }

    const onSubmit = (): void => {
        setErrorState('')
        setSendTouchPointState(SendTouchPointState.SENDING)
        sendTouchPoint({
            variables: {
                input: {
                    bookingID: orderID,
                    key: t(selectedTouchPoint.selected),
                    value: selectedTouchPoint.input,
                },
            },
        })
    }

    return {
        loadState,
        viewType,
        salesPartner,
        newOrder,
        customizeJsData,
        selectedTouchPoint,
        setSelectedTouchPoint,
        onSubmit,
        errorState,
        sendTouchPointState,
        clientData,
    }
}
