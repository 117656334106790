import { Box, Grid } from '@material-ui/core'
import ViewWrapper from 'components/ViewWrapper'
import { useTranslation } from 'react-i18next'
import useURLParams from 'utils/URLParamsContex'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import RadioOptionGroup from '../../components/RadioOptionGroup'
import SwitchMonth from '../../components/SwitchMonth'
import { getSubmitButtonTextForProductBasedViews } from '../../utils/testable/getSubmitButtonTextForProductBasedViews'
import { useProductSelectionReducer } from './useProductSelectionReducer'

const ProductSelection = (): JSX.Element => {
    const { t } = useTranslation()
    const {
        availableProductCategories,
        currentView,
        selectedProductCategoryFromData,
        selectedContractLength,
        monthOptions,
        setSelectedContractLength,
        setSelectedProduct,
        productsToShow,
        selectedProductCategory,
        currentPage,
        pagesList,
        customizeJsData,
        disabledSubmit,
    } = useProductSelectionReducer()
    const { B2B } = useURLParams()
    const [userInfo] = useUserInfo()

    const submitButtonText = getSubmitButtonTextForProductBasedViews(pagesList, currentPage, availableProductCategories)

    return (
        <ViewWrapper
            disabledSubmit={disabledSubmit}
            header={getRoleAwareTranslationKey(
                userInfo,
                customizeJsData,
                currentView +
                    'Strings.header.' +
                    (selectedProductCategoryFromData ? selectedProductCategoryFromData.identifier : ''),
            )}
            subHeader={getRoleAwareTranslationKey(
                userInfo,
                customizeJsData,
                currentView +
                    'Strings.subheader.' +
                    (selectedProductCategoryFromData ? selectedProductCategoryFromData.identifier : ''),
            )}
            submitButtonText={
                typeof submitButtonText === 'string'
                    ? t(submitButtonText as string)
                    : t(currentView + 'Strings.goTo', {
                          productCategoryType: t(
                              (
                                  submitButtonText as {
                                      productCategoryType: string
                                      selectionStep: string
                                  }
                              ).productCategoryType,
                          ),
                          selectionStep: t(
                              (
                                  submitButtonText as {
                                      productCategoryType: string
                                      selectionStep: string
                                  }
                              ).selectionStep,
                          ),
                      })
            }
            tooltipText={
                t(
                    currentView +
                        'Strings.' +
                        'infoText' +
                        (selectedProductCategoryFromData ? '.' + selectedProductCategoryFromData.identifier : ''),
                ) ===
                currentView +
                    'Strings.' +
                    'infoText' +
                    (selectedProductCategoryFromData ? '.' + selectedProductCategoryFromData.identifier : '')
                    ? ''
                    : t(
                          currentView +
                              'Strings.' +
                              'infoText' +
                              (selectedProductCategoryFromData ? '.' + selectedProductCategoryFromData.identifier : ''),
                      )
            }
            viewType={currentView}
        >
            <>
                {selectedProductCategoryFromData && monthOptions.length > 1 && (
                    <Grid container style={{ justifyContent: 'flex-end' }}>
                        <Box width={{ xs: 1, sm: 'auto' }}>
                            <SwitchMonth
                                monthOptions={monthOptions}
                                onSelect={setSelectedContractLength}
                                selected={selectedContractLength}
                            />
                        </Box>
                    </Grid>
                )}
                {selectedProductCategoryFromData && (
                    <Box width={1} marginTop={3}>
                        <RadioOptionGroup
                            onSelect={(id): void => {
                                setSelectedProduct({
                                    productCategoryId: selectedProductCategoryFromData.id,
                                    productId: id,
                                    B2B,
                                })
                            }}
                            options={productsToShow}
                            selected={
                                selectedProductCategory && selectedProductCategory.selectedProduct
                                    ? selectedProductCategory.selectedProduct.id
                                    : ''
                            }
                        />
                    </Box>
                )}
            </>
        </ViewWrapper>
    )
}

export default ProductSelection
