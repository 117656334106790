import { Grid, TextField } from '@material-ui/core'
import { Customize } from 'graphql/types'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { PortabilityViewFields } from 'store/PortabilityState/PortabilityState.reducer'
import TestIDs from 'utils/TestIDs'
import RenderRadios from './RenderRadios'

export interface RenderTerminatedContractProps {
    endOfContract: string
    link?: string
    onChange: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    selected: boolean
    customizeJsData: Customize | undefined
    missingFields: string[]
}
const RenderTerminatedContract = (props: RenderTerminatedContractProps): JSX.Element => {
    const { endOfContract, link, missingFields, onChange, selected, customizeJsData } = props
    const { t } = useTranslation()
    const field = PortabilityViewFields.TERMINATED_CONTRACT

    useEffect(() => {
        if (selected) {
            onChange(field, 'EndOfContract', -1, '')
        }
    }, [selected])

    return (
        <>
            <Grid container spacing={1} style={{ marginBottom: '15px', marginTop: '45px' }}>
                <RenderRadios
                    customizeJsData={customizeJsData}
                    field={field}
                    link={link}
                    onChange={onChange}
                    selected={selected}
                />
                {!selected && (
                    <>
                        <Grid item xs={12} md={12}>
                            <InputMask
                                mask={'99.99.9999'}
                                alwaysShowMask={false}
                                placeholder={''}
                                value={endOfContract}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                    if (e.target.value !== endOfContract) {
                                        onChange(field, 'EndOfContract', -1, e.target.value)
                                    }
                                }}
                            >
                                {(): JSX.Element => (
                                    <TextField
                                        {...TestIDs.GET_PORTABILITY_DETAILS('INPUT', field + '_END_OF_CONTRACT')}
                                        fullWidth={true}
                                        label={t('portabilityDetailsStrings.' + field + '.EndOfContract')}
                                        variant={'outlined'}
                                        margin={'dense'}
                                        InputProps={
                                            missingFields
                                                ? {
                                                      className:
                                                          missingFields.find(
                                                              (missingField) => missingField === 'terminationDate',
                                                          ) !== undefined
                                                              ? 'TextError'
                                                              : '',
                                                  }
                                                : {}
                                        }
                                    />
                                )}
                            </InputMask>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default RenderTerminatedContract
