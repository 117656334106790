import { sumBy } from 'lodash'
import { OptionItemContent } from '../../components/OptionItem/OptionItem'
import { Discount, Product } from '../../graphql/types'

export const productsToRadioOptionGroup = (products: Product[], b2b: boolean): OptionItemContent[] => {
    const optionItemsToReturn: OptionItemContent[] = []

    products
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .forEach((product) => {
            const productPrice = b2b ? product.priceNet : product.gross
            let description: string[] | undefined
            let oldPrice: number | undefined
            let newPrice: number = productPrice
            if (product.discounts.length > 0) {
                description = product.discounts.map((discount) => discount.description)
                oldPrice = productPrice
                newPrice =
                    oldPrice -
                    sumBy(product.discounts, function (d: Discount) {
                        return b2b ? d.priceNet : d.gross
                    })
            }

            optionItemsToReturn.push({
                id: product.id,
                title: product.title,
                price: {
                    billMode: product.billMode,
                    newPrice,
                    oldPrice,
                    description,
                    info: product.information
                        ? {
                              title: product.information,
                          }
                        : undefined,
                },
                selectedImage: product.badgeIdentifier ? product.badgeIdentifier + 'FreeActive.png' : undefined,
                unselectedImage: product.badgeIdentifier ? product.badgeIdentifier + 'FreeInactive.png' : undefined,
                subtitle: product.subtitle ? product.subtitle : undefined,
            })
        })

    return optionItemsToReturn
}
