import { ApolloProvider } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { Box, CircularProgress, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useKeycloak } from '@react-keycloak/web'
import CustomizeLoader from 'components/CustomizeLoader'
import ErrorDialog from 'components/ErrorDialog'
import Footer from 'components/Footer'
import HeaderComponent from 'components/HeaderComponent'
import InactiveDialog from 'components/InactiveDialog'
import SaveProgress from 'components/SaveProgress'
import UserBar from 'components/UserBar'
import deLocale from 'date-fns/locale/de'
import { ErrorConfig } from 'graphql/types'
import BankDetails from 'pages/BankDetails'
import CustomerDataLoad from 'pages/CustomerDataLoad'
import DesiredDate from 'pages/DesiredDate'
import Edit from 'pages/Edit'
import EditPortability from 'pages/Edit/EditPortability'
import InstallationDetails from 'pages/InstallationDetails'
import ManualVoucherRedemption from 'pages/ManualVoucherRedemption'
import NoMatch from 'pages/NoMatch'
import OrderConfirmation from 'pages/OrderConfirmation'
import OrderDataLoad from 'pages/OrderDataLoad'
import PortabilityView from 'pages/PortabilityView'
import ProductCategoriesSelection from 'pages/ProductCategoriesSelection'
import TelephoneConfigurationView from 'pages/TelephoneConfigurationView'
import VZFView from 'pages/VZF'
import VZFLandingpage from 'pages/VZFLandingpage'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import store from 'store/store'
import { ModifiedTheme } from 'theme/theme'
import 'utils/ i18n'
import { URLParams, URLParamsContext } from 'utils/URLParamsContex'
import { useUserInfo } from 'utils/UserInfoContext'
import getEnvConfig from 'utils/getEnvConfig'
import AvailabilityCheck from './pages/AvailabilityCheck/AvailabilityCheck'
import ContactData from './pages/ContactData/ContactData'
import CrossSelling from './pages/CrossSelling'
import OptionsConfiguration from './pages/OptionsConfiguration'
import OrderOverview from './pages/OrderOverview'
import ProductSelection from './pages/ProductSelection'
import ProductTypeSelection from './pages/ProductTypeSelection'
import { useApolloClient } from './useApolloClient'

function App(): JSX.Element {
    const { t } = useTranslation()
    const envConfig = getEnvConfig()
    const { initialized, keycloak } = useKeycloak()
    const graphQLServerURI =
        process.env.NODE_ENV !== 'production'
            ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/graphql`
            : '/graphql'

    const [error, setError] = React.useState<ErrorConfig>()
    const apolloClient = useApolloClient(graphQLServerURI, setError)

    const [userInfo, setUserInfo] = useUserInfo()

    const logout = useCallback(() => {
        keycloak?.logout()
        setUserInfo(null)
    }, [keycloak])

    // NOTE: THIS is needed otherwise it will end up in a cycle reloading the first page endless.
    if (!initialized) {
        return <></>
    }

    return (
        <>
            {apolloClient && (
                <ApolloProvider client={apolloClient}>
                    <HashRouter>
                        <ThemeProvider theme={ModifiedTheme}>
                            {error && <ErrorDialog errorConfig={error} />}
                            {!error && (
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                    <Provider store={store.store}>
                                        <PersistGate loading={<CircularProgress />} persistor={store.persistor}>
                                            <URLParamsContext.Provider value={URLParams()}>
                                                <CustomizeLoader>
                                                    <>
                                                        <UserBar
                                                            userInfo={userInfo}
                                                            onLogoutClick={logout}
                                                            classProps={'userBarClass'}
                                                        />
                                                        <HeaderComponent />
                                                        <InactiveDialog />
                                                        <Box>
                                                            <Switch>
                                                                <Route
                                                                    exact
                                                                    path={'/load/:id/:token'}
                                                                    component={OrderDataLoad}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/catv/:data'}
                                                                    component={CustomerDataLoad}
                                                                />
                                                                <Route exact path={'/'}>
                                                                    <Redirect
                                                                        to={'/' + t(ViewType.AVAILABILITY_CHECK)}
                                                                    />
                                                                </Route>

                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.EDIT)}
                                                                    component={Edit}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.EDIT_PORTABILITY)}
                                                                    component={EditPortability}
                                                                />

                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.AVAILABILITY_CHECK)}
                                                                    component={AvailabilityCheck}
                                                                />

                                                                <Route
                                                                    exact
                                                                    path={
                                                                        '/' + t(ViewType.PRODUCT_CATEGORIES_SELECTION)
                                                                    }
                                                                    component={ProductCategoriesSelection}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.PRODUCT_SELECTION) + '/:id'}
                                                                    component={ProductSelection}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={
                                                                        '/' +
                                                                        t(ViewType.PRODUCT_TYPE_SELECTION) +
                                                                        '/:id/:productType'
                                                                    }
                                                                    component={ProductTypeSelection}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={
                                                                        '/' +
                                                                        t(ViewType.OPTIONS_CONFIGURATION) +
                                                                        '/:viewType/:productID/:productType?'
                                                                    }
                                                                    component={OptionsConfiguration}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={
                                                                        '/' +
                                                                        t(ViewType.PRODUCT_CROSS_SELLING) +
                                                                        '/:targetId'
                                                                    }
                                                                    component={CrossSelling}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.VZF_VIEW)}
                                                                    component={VZFView}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.VZF_LANDINGPAGE)}
                                                                    component={VZFLandingpage}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.CONTACT_DATA)}
                                                                    component={ContactData}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.LANDLINE_CONTRACT_OPTIONS)}
                                                                    component={TelephoneConfigurationView}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.PORTABILITY_DETAILS)}
                                                                    component={PortabilityView}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.BANK_DETAILS)}
                                                                    component={BankDetails}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.INSTALLATION_DETAILS)}
                                                                    component={InstallationDetails}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.DESIRED_DATE)}
                                                                    component={DesiredDate}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.MANUAL_VOUCHER_REDEMPTION)}
                                                                    component={ManualVoucherRedemption}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.ORDER_OVERVIEW)}
                                                                    component={OrderOverview}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path={'/' + t(ViewType.ORDER_CONFIRMATION)}
                                                                    component={OrderConfirmation}
                                                                />
                                                                <Route exact path={'*'} component={NoMatch} />
                                                            </Switch>
                                                        </Box>
                                                        <SaveProgress checkboxLabel={t('saveOrderProgressConsent')} />
                                                        <Footer />
                                                    </>
                                                </CustomizeLoader>
                                            </URLParamsContext.Provider>
                                        </PersistGate>
                                    </Provider>
                                </MuiPickersUtilsProvider>
                            )}
                        </ThemeProvider>
                    </HashRouter>
                </ApolloProvider>
            )}
        </>
    )
}

export default App
