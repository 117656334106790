import { useMutation } from '@apollo/client'
import { SaveOrderProgessState } from 'components/SaveProgress/useSaveProgressState'
import SaveOrderProgress from 'graphql/mutations/SaveOrderProgress'
import { Response } from 'graphql/types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useSelector } from 'react-redux'
import { CombinedState } from 'redux'
import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import { BankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import { ContactDataState } from 'store/ContactData/ContactData.reducer'
import { GeneralState, Page } from 'store/GeneralState/GeneralState.reducer'
import { PortabilityState } from 'store/PortabilityState/PortabilityState.reducer'
import { AppState } from 'store/store'
import { convertStateToOrderData } from 'utils/convertStateToOrderData'
import { encryption, generateKeyAndExportIt } from 'utils/crypto'
import { mailIsValid } from 'utils/testable/functions'
import useURLParams from 'utils/URLParamsContex'
import { isSalesPartner, useUserInfo } from 'utils/UserInfoContext'

interface InactiveDialogStateReturn {
    currentPage: number
    open: boolean
    mailValid: boolean
    pagesList: Page[]
    email: string
    saveOrderProgessState: SaveOrderProgessState
    errorState: string
    handleClose: () => void
    handleSend: () => void
    dataPrivacyChecked: boolean
    setDataPrivacyChecked: Dispatch<SetStateAction<boolean>>
    confirmEmail: string
    setConfirmEmail: Dispatch<SetStateAction<string>>
    emailError: boolean
    setEmailError: Dispatch<SetStateAction<boolean>>
    setEmail: Dispatch<SetStateAction<string>>
    vzfID: string
}

const useInactiveDialogState: () => InactiveDialogStateReturn = () => {
    const { vzfID, currentPage, pagesList, state, customizeJsData, clientData } = useSelector((appState: AppState) => ({
        state: appState,
        pagesList: appState.generalState.pagesList,
        currentPage: appState.generalState.currentPage,
        vzfID: appState.generalState.vzfID,
        customizeJsData: appState.generalState.customizeJsData,
        clientData: appState.generalState.clientData,
    }))
    const { B2B } = useURLParams()
    const [dataPrivacyChecked, setDataPrivacyChecked] = useState(false)
    const [open, setOpen] = useState(false)
    const [saveOrderProgessState, setSaveOrderProgessState] = useState<SaveOrderProgessState>(
        SaveOrderProgessState.READY,
    )
    const [saveOrderProgressCounter, setSaveOrderProgressCounter] = useState(1)
    const [errorState, setErrorState] = useState('')
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [emailError, setEmailError] = useState(false)

    const handleOnIdle = (): void => {
        if (!open) setOpen(true)
    }

    const [user] = useUserInfo()

    const { reset, pause } = useIdleTimer({
        timeout: 300000,
        onIdle: handleOnIdle,
        stopOnIdle: open,
        startOnMount: false,
    })

    useEffect(() => {
        if (currentPage >= 1 && !isSalesPartner(user?.roles ?? [], customizeJsData)) {
            reset()
        } else {
            pause()
        }
    }, [currentPage])

    const [saveOrderProgress] = useMutation(SaveOrderProgress, {
        onCompleted: (data: { saveOrderProgress: Response }) => {
            if (data && data.saveOrderProgress.status === 'send_email') {
                setErrorState('send_email')
            }

            const timeout = saveOrderProgressCounter * 5000
            setSaveOrderProgessState(SaveOrderProgessState.SENT)
            setOpen(!open)
            setTimeout(() => {
                setSaveOrderProgessState(SaveOrderProgessState.READY)
                setEmail('')
                setConfirmEmail('')
                setDataPrivacyChecked(false)
            }, timeout)
            setSaveOrderProgressCounter(saveOrderProgressCounter + 1)
        },
    })

    const onSendClick = (
        state: CombinedState<{
            availabilityCheck: AvailabilityCheckState
            bankDetails: BankDetailsState
            contactData: ContactDataState
            generalState: GeneralState
            portabilityState: PortabilityState
        }>,
        email: string,
        confirmEmail: string,
    ): void => {
        let salutation = ''
        if (
            state.contactData.personalSalutation.trim().length !== 0 &&
            state.contactData.personalName.trim().length !== 0 &&
            state.contactData.personalLastName.trim().length !== 0
        ) {
            switch (state.contactData.personalSalutation) {
                case 'Firma':
                    salutation = state.contactData.personalName + ' ' + state.contactData.personalLastName
                    break
                case 'Divers':
                    salutation = state.contactData.personalName + ' ' + state.contactData.personalLastName
                    break
                default:
                    salutation =
                        state.contactData.personalSalutation +
                        ' ' +
                        state.contactData.personalName +
                        ' ' +
                        state.contactData.personalLastName
            }
        }

        if (email === confirmEmail) {
            if (dataPrivacyChecked) {
                setSaveOrderProgessState(SaveOrderProgessState.SENDING)
                setErrorState('')

                const orderData = convertStateToOrderData(state, B2B)
                generateKeyAndExportIt().then(([key, hexKey]) => {
                    encryption(orderData, key).then((encryptedData) => {
                        saveOrderProgress({
                            variables: {
                                email,
                                data: encryptedData,
                                key: hexKey,
                                salutation,
                                vzfID,
                                clientID: clientData ? clientData.clientID : undefined,
                                orderProcessType: state.generalState.orderProcessType,
                            },
                        })
                    })
                })
            }
        } else {
            setEmailError(true)
        }
    }

    const handleClose = (): void => {
        setErrorState('')
        setOpen(false)
    }

    const handleSend = (): void => {
        setErrorState('')
        onSendClick(state, email, confirmEmail)
    }

    const mailValid = mailIsValid(email)

    return {
        currentPage,
        email,
        errorState,
        mailValid,
        open,
        pagesList,
        saveOrderProgessState,
        handleClose,
        handleSend,
        dataPrivacyChecked,
        setDataPrivacyChecked,
        confirmEmail,
        setConfirmEmail,
        emailError,
        setEmailError,
        setEmail,
        vzfID,
    }
}

export default useInactiveDialogState
