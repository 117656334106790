import gql from 'graphql-tag'

export default gql`
    query checkVZF($vzf: String!) {
        checkVZF(vzf: $vzf) {
            isValid
            vzf {
                selectedProductCategories
                multipleSelectOptionList {
                    key
                    value
                }
                startOfDelivery
                startOfMarketing
                zipcode
                city
                street
                houseNumber
                additionalInfo
                email
                customizeType
                clientData {
                    id
                    salutation
                    name
                    lastName
                    company
                    zipcode
                    city
                    street
                    housenumber
                    telephoneNumber
                    email
                    website
                    management
                    register
                    registerCourt
                    taxID
                    linkAGB
                    linkImpressum
                    linkDataPrivacy
                    salesPartner
                    salesPartnerFullName
                    state
                    clientID
                    logo {
                        name
                        uploadDate
                        size
                    }
                }
                clientID
            }
            vzfID
        }
    }
`
