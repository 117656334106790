import gql from 'graphql-tag'

export default gql`
    query AvailableAdditions(
        $zipcode: String!
        $city: String!
        $street: String!
        $district: String
        $houseNumber: String!
    ) {
        availableAdditions(
            zipcode: $zipcode
            city: $city
            street: $street
            district: $district
            houseNumber: $houseNumber
        )
    }
`
