import { Box, ButtonBase, Link, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'
import colors from 'theme/colors'
import { UserInfo, useUserInfo } from 'utils/UserInfoContext'

export interface UserBarProps {
    userInfo: UserInfo | null
    onLogoutClick: () => void
    classProps?: string
}

const UserBar = (props: UserBarProps): JSX.Element => {
    const { classProps, onLogoutClick } = props
    const { t } = useTranslation()
    const { customizeJsData } = useSelector((appState: AppState) => {
        return {
            customizeJsData: appState.generalState.customizeJsData,
        }
    })

    const [userInfo] = useUserInfo()

    return (
        <>
            {customizeJsData &&
                !customizeJsData.globalConfiguration.enableLoadingClient &&
                userInfo &&
                userInfo.roles.includes('Vertriebspartner') && (
                    <Box
                        width={1}
                        bgcolor={colors.green}
                        display={'flex'}
                        justifyContent={'flex-end'}
                        color={colors.white}
                        paddingLeft={1.5}
                        className={classProps}
                        // {...TestIDs.GET_BASE('USER_BAR')}
                    >
                        <Box
                            display={'flex'}
                            justifySelf={'start'}
                            paddingTop={0.5}
                            paddingBottom={0.5}
                            style={{ flex: 1 }}
                        >
                            <Link
                                className={'AdminLink'}
                                href={
                                    process.env.NODE_ENV !== 'production' ? 'http://localhost:3033/admin/' : '/admin/'
                                }
                                color={'secondary'}
                                underline={'none'}
                            >
                                {t('userBar.admin')}
                            </Link>
                        </Box>
                        <Box
                            display={'flex'}
                            paddingTop={0.5}
                            paddingBottom={0.5}
                            borderRight={`2px solid ${colors.white}`}
                        >
                            <Box
                                marginRight={{ xs: 0.75, sm: 1.5 }}
                                lineHeight={'normal'}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <img alt={'person'} src={process.env.PUBLIC_URL + '/customize/icons/person.svg'} />
                            </Box>
                            <Box
                                paddingRight={{ xs: 0.75, sm: 1.5 }}
                                lineHeight={'normal'}
                                display={'flex'}
                                alignItems={'center'}
                                textAlign={{ xs: 'center', sm: 'left' }}
                            >
                                <Typography>{userInfo?.name ?? ''}</Typography>
                            </Box>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} style={{ paddingRight: 12 }}>
                            <ButtonBase
                                component={Typography}
                                className={classProps}
                                style={{ flex: 1, paddingLeft: 12, paddingRight: 12, height: '100%' }}
                                onClick={onLogoutClick}
                            >
                                {t('userBar.logout')}
                            </ButtonBase>
                        </Box>
                    </Box>
                )}
        </>
    )
}

export default UserBar
