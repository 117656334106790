import { ViewType } from '../../store/GeneralState/GeneralState.reducer'

export const pathToViewType = (path: string): ViewType => {
    switch (path.split('/')[1]) {
        case 'Produktkategorieauswahl':
            return ViewType.PRODUCT_CATEGORIES_SELECTION
        case 'Produktauswahl':
            return ViewType.PRODUCT_SELECTION
        case 'Produkttypauswahl':
            return ViewType.PRODUCT_TYPE_SELECTION
        case 'Produktoptionen':
            return ViewType.OPTIONS_CONFIGURATION
        case 'Querverkauf':
            return ViewType.PRODUCT_CROSS_SELLING
        case 'Kontaktdaten':
            return ViewType.CONTACT_DATA
        case 'FestnetzVertragsOptionen':
            return ViewType.LANDLINE_CONTRACT_OPTIONS
        case 'Rufnummernmitnahme':
            return ViewType.PORTABILITY_DETAILS
        case 'Zahlungsdetails':
            return ViewType.BANK_DETAILS
        case 'Installationsdetails':
            return ViewType.INSTALLATION_DETAILS
        case 'Wunschtermin':
            return ViewType.DESIRED_DATE
        case 'Gutscheineingabe':
            return ViewType.MANUAL_VOUCHER_REDEMPTION
        case 'Bestellüberprüfung':
            return ViewType.ORDER_OVERVIEW
        case 'VielenDank':
            return ViewType.ORDER_CONFIRMATION
        case 'VZF':
            return ViewType.VZF_VIEW
        case 'VZFLandingpage':
            return ViewType.VZF_LANDINGPAGE
        default:
            return ViewType.AVAILABILITY_CHECK
    }
}
