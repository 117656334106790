import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import colors from 'theme/colors'
import useCustomerDataLoad from './useCustomerDataLoad'

export interface CustomerDataLoadProps {
    data: string
}

const CustomerDataLoad = (props: RouteComponentProps<CustomerDataLoadProps>): JSX.Element => {
    const { match } = props
    const { loadState, error, onClickReturn } = useCustomerDataLoad(match.params)
    const { t } = useTranslation()

    return (
        <Box
            bgcolor={colors.white}
            display={'flex'}
            flexDirection={'column'}
            flex={1}
            justifyContent={'center'}
            alignItems={'center'}
            height={'90vh'}
        >
            {loadState.loading && (
                <>
                    <CircularProgress style={{ color: colors.green }} size={'140px'} />
                    <Typography style={{ marginTop: '40px' }}>LOADING</Typography>
                </>
            )}
            {!loadState.loading && (
                <>
                    <Typography style={{ marginTop: '40px', marginBottom: '40px', fontSize: '20px', fontWeight: 550 }}>
                        {t('loadCustomerData.' + error)}
                    </Typography>
                    <Button onClick={onClickReturn} className={clsx(['FormButton', 'NextButton'])}>
                        {t('loadCustomerData.return')}
                    </Button>
                </>
            )}
        </Box>
    )
}

export default CustomerDataLoad
