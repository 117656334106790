// Suggestions
// https://webshop.synvia.de/#/Verfügbarkeitsprüfung
// ID: "Weiter-Button" (verf_weit)
// ID: "Bestellung Fortsetzen" (verf_fort)
// https://webshop.synvia.de/#/Querverkauf/television
// ID: "Ja" (qtv_ja)
// ID: "Weiter-Button" (qtv_weit)
// https://webshop.synvia.de/#/Querverkauf/internet
// ID: "Ja" (qip_ja)
// ID: "Weiter-Button" (qip_weit)
// https://webshop.synvia.de/#/VZF
// ID: "E-Mail Senden" (vzf_mail)
// ID: "hier herunterladen" (vzf_lad)
// Footer "Bestellung später fortsetzen
// ID: "Link senden" (spaeter)
// https://webshop.synvia.de/#/Gutscheineingabe
// ID: "Ich möchte einen Gutschein Code eingeben" (gut_ja)
// ID: "weiter mit Wunschtermin" (gut_weit)
// https://webshop.synvia.de/#/Bestellüberprüfung
// ID: "VZF Direkt Herunterladen" (bes_vzf)
// ID: "Kostenpflichtig bestellen" (bes_order)

import { Page } from 'store/GeneralState/GeneralState.reducer'

interface ETrackerCode {
    [key: string]: string
}

export const getETrackerCodeByPage = (currentPage: Page, isVZFCheck: boolean): string => {
    switch (currentPage.path) {
        case '/Bestellüberprüfung':
            return ETrackerCodeList.order_overView_download_finish
        case '/Wunschtermin':
            return ETrackerCodeList.desired_date_continue
        case '/Gutscheineingabe':
            return ETrackerCodeList.voucher_continue
        case '/Installationsdetails':
            return ''
        case '/Zahlungsdetails':
            return ''
        case '/Rufnummernmitnahme':
            return ''
        case '/FestnetzVertragsOptionen':
            return ''
        case '/Kontaktdaten':
            return ''
        case '/VZF':
            return ''
        case '/Querverkauf/television':
            return ETrackerCodeList.cross_selling_tv_continue
        case '/Querverkauf/internet':
            return ETrackerCodeList.cross_selling_internet_continue
        case '/Querverkauf/telephone':
            return ETrackerCodeList.cross_selling_telephone_continue
        case '/Produktkategorieauswahl':
            return ''
        case '/':
            if (isVZFCheck) {
                return ETrackerCodeList.vzf_continue
            } else {
                return ETrackerCodeList.aviableCheck
            }
        default:
            return ''
    }
}

export const ETrackerCodeList: ETrackerCode = {
    aviableCheck: 'verf_weit',
    vzf_continue: 'verf_fort',
    cross_selling_tv_yes: 'qtv_ja',
    cross_selling_tv_no: 'qtv_nein',
    cross_selling_tv_continue: 'qtv_weit',
    cross_selling_internet_yes: 'qip_ja',
    cross_selling_internet_no: 'qip_nein',
    cross_selling_internet_continue: 'qip_weit',
    cross_selling_telephone_yes: 'qtel_ja',
    cross_selling_telephone_no: 'qtel_nein',
    cross_selling_telephone_continue: 'qtel_weit',
    vzf_send_mail: 'vzf_mail',
    vzf_download: 'vzf_lad',
    later: 'spaeter',
    voucher_yes: 'gut_ja',
    voucher_no: 'gut_nein',
    voucher_continue: 'gut_weit',
    desired_date_yes: 'wun_ja',
    desired_date_no: 'wun_nein',
    desired_date_continue: 'wun_weit',
    order_overView_download_vzf: 'bes_vzf',
    order_overView_download_finish: 'bes_order',
    touchpoints_send: 'touchpoints',
}
