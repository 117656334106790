import { PersistState } from 'redux-persist'
import AvailabilityCheckActions, { AvailabilityCheckAction, AvailabilityCheckField } from './AvailabilityCheck.actions'

export interface AvailabilityCheckState {
    cities: string[]
    focusedFieldId: AvailabilityCheckField
    houseNumbers: string[]
    selectedCity: string
    selectedHouseNumber: string
    selectedStreet: string
    streets: string[]
    selectedAddition: string
    additions: string[]
    selectedDistrict: string
    districts: string[]
    zip: string
    vzf: string
    vzfNoResult: boolean
    _persist: PersistState
}

export const initialAvailabilityCheckState: AvailabilityCheckState = {
    cities: [],
    focusedFieldId: AvailabilityCheckField.ZIP,
    houseNumbers: [],
    selectedCity: '',
    selectedHouseNumber: '',
    selectedStreet: '',
    streets: [],
    selectedDistrict: '',
    districts: [],
    selectedAddition: '',
    additions: [],
    zip: '',
    vzf: '',
    vzfNoResult: false,
    _persist: { rehydrated: false, version: 1 } as PersistState,
}

function AvailabilityCheckReducer(
    state: AvailabilityCheckState = initialAvailabilityCheckState,
    action: AvailabilityCheckActions,
): AvailabilityCheckState {
    switch (action.type) {
        case AvailabilityCheckAction.SET_TO_INIT_STATE:
            return {
                ...initialAvailabilityCheckState,
            }
        case AvailabilityCheckAction.SET_CITIES:
            return {
                ...state,
                cities: action.payload,
            }
        case AvailabilityCheckAction.SET_HOUSE_NUMBERS:
            return {
                ...state,
                houseNumbers: action.payload,
            }
        case AvailabilityCheckAction.SET_STREETS:
            return {
                ...state,
                streets: action.payload,
            }
        case AvailabilityCheckAction.SET_DISTRICT:
            return {
                ...state,
                districts: action.payload,
            }
        case AvailabilityCheckAction.SET_ADDITION:
            return {
                ...state,
                additions: action.payload,
            }
        case AvailabilityCheckAction.SET_SELECT_CITY:
            return {
                ...state,
                selectedCity: action.payload,
                houseNumbers: [],
                selectedHouseNumber: '',
                selectedStreet: '',
                streets: [],
                districts: [],
                selectedDistrict: '',
                additions: [],
                selectedAddition: '',
            }
        case AvailabilityCheckAction.SET_SELECT_DISTRICT:
            return {
                ...state,
                selectedDistrict: action.payload,
                houseNumbers: [],
                selectedHouseNumber: '',
                selectedStreet: '',
                streets: [],
                additions: [],
                selectedAddition: '',
            }
        case AvailabilityCheckAction.SET_SELECT_ADDITION:
            return {
                ...state,
                selectedAddition: action.payload,
            }
        case AvailabilityCheckAction.SET_SELECT_HOUSE_NUMBER:
            return {
                ...state,
                selectedHouseNumber: action.payload,
                additions: [],
                selectedAddition: '',
            }
        case AvailabilityCheckAction.SET_SELECT_STREET:
            return {
                ...state,
                selectedStreet: action.payload,
                houseNumbers: [],
                selectedHouseNumber: '',
                additions: [],
                selectedAddition: '',
            }
        case AvailabilityCheckAction.SET_ZIP:
            if (action.payload.length === 5) {
                return {
                    ...state,
                    zip: action.payload,
                }
            } else {
                return {
                    ...state,
                    zip: action.payload,
                    cities: [],
                    houseNumbers: [],
                    selectedCity: '',
                    selectedHouseNumber: '',
                    selectedStreet: '',
                    streets: [],
                    districts: [],
                    selectedDistrict: '',
                    additions: [],
                    selectedAddition: '',
                }
            }
        case AvailabilityCheckAction.SET_FOCUSED_FIELD:
            return {
                ...state,
                focusedFieldId: action.payload,
            }
        case AvailabilityCheckAction.SET_AVAILABILITY_STATE:
            return action.payload
        case AvailabilityCheckAction.SET_VZF:
            return { ...state, vzf: action.payload, vzfNoResult: false }
        case AvailabilityCheckAction.SET_VZF_NO_RESULT:
            return { ...state, vzfNoResult: action.payload }
        default:
            return state
    }
}

export default AvailabilityCheckReducer
