import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import * as React from 'react'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import colors from 'theme/colors'
import { getTestIDOnViewType } from 'utils/TestIDs'

export interface CheckboxComponentProps {
    onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
    label: string
    name?: string
    testIdentifier: {
        viewType: ViewType
        input: string
        category?: string
    }
    checked: boolean
    hidden?: boolean
}

const CheckboxComponent = (props: CheckboxComponentProps): JSX.Element => {
    const { onSelect, label, checked, name, testIdentifier, hidden } = props

    return (
        <FormControlLabel
            hidden={hidden !== undefined ? hidden : false}
            name={name}
            labelPlacement={'end'}
            control={
                <Checkbox
                    {...getTestIDOnViewType(testIdentifier.viewType, testIdentifier.input, testIdentifier.category)}
                    onChange={onSelect}
                    checked={checked}
                    name={name}
                    style={{ color: colors.black, padding: 0 }}
                />
            }
            label={
                <Typography
                    variant={'h6'}
                    className={'BankDetailsCheckboxLabel'}
                    dangerouslySetInnerHTML={{ __html: label }}
                />
            }
        />
    )
}

export default CheckboxComponent
