import ViewWrapper from 'components/ViewWrapper'
import { useTranslation } from 'react-i18next'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import OptionsCategory from '../../components/OptionsCategory'
import { getSubmitButtonTextForProductBasedViews } from '../../utils/testable/getSubmitButtonTextForProductBasedViews'
import { useOptionsConfigurationReducer } from './useOptionsConfiguration'

const OptionsConfiguration = (): JSX.Element => {
    const { t } = useTranslation()
    const {
        currentView,
        optionsCategoriesToShow,
        productTypeFromData,
        availableProductCategories,
        currentPage,
        pagesList,
        disabledSubmit,
        customizeJsData,
        viewType,
    } = useOptionsConfigurationReducer()

    const headerType = viewType === 'hardware' ? 'hardware.' : 'service.'

    const [userInfo] = useUserInfo()
    return (
        <ViewWrapper
            disabledSubmit={disabledSubmit}
            header={getRoleAwareTranslationKey(
                userInfo,
                customizeJsData,
                currentView +
                    'Strings.header.' +
                    headerType +
                    (productTypeFromData ? productTypeFromData.identifier : ''),
            )}
            subHeader={getRoleAwareTranslationKey(
                userInfo,
                customizeJsData,
                t(
                    currentView +
                        'Strings.subheader.' +
                        headerType +
                        (productTypeFromData ? productTypeFromData.identifier : ''),
                ) !==
                    currentView +
                        'Strings.subheader.' +
                        headerType +
                        (productTypeFromData ? productTypeFromData.identifier : '')
                    ? currentView +
                          'Strings.subheader.' +
                          headerType +
                          (productTypeFromData ? productTypeFromData.identifier : '')
                    : '',
            )}
            submitButtonText={
                typeof getSubmitButtonTextForProductBasedViews(pagesList, currentPage, availableProductCategories) ===
                'string'
                    ? t(
                          getSubmitButtonTextForProductBasedViews(
                              pagesList,
                              currentPage,
                              availableProductCategories,
                          ) as string,
                      )
                    : t(currentView + 'Strings.goTo', {
                          productCategoryType: t(
                              (
                                  getSubmitButtonTextForProductBasedViews(
                                      pagesList,
                                      currentPage,
                                      availableProductCategories,
                                  ) as {
                                      productCategoryType: string
                                      selectionStep: string
                                  }
                              ).productCategoryType,
                          ),
                          selectionStep: t(
                              (
                                  getSubmitButtonTextForProductBasedViews(
                                      pagesList,
                                      currentPage,
                                      availableProductCategories,
                                  ) as {
                                      productCategoryType: string
                                      selectionStep: string
                                  }
                              ).selectionStep,
                          ),
                      })
            }
            tooltipText={
                t(
                    currentView +
                        'Strings.' +
                        'infoText' +
                        (productTypeFromData ? '.' + productTypeFromData.identifier : ''),
                ) ===
                currentView +
                    'Strings.' +
                    'infoText' +
                    (productTypeFromData ? '.' + productTypeFromData.identifier : '')
                    ? ''
                    : t(
                          currentView +
                              'Strings.' +
                              'infoText' +
                              (productTypeFromData ? '.' + productTypeFromData.identifier : ''),
                      )
            }
            viewType={currentView}
        >
            <>
                {optionsCategoriesToShow.length > 0 &&
                    optionsCategoriesToShow.map((value) => (
                        <OptionsCategory key={value.categoryId + value.selected} {...value} />
                    ))}
            </>
        </ViewWrapper>
    )
}

export default OptionsConfiguration
