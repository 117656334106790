import { MultipleSelectOptions, Option, OptionCategory, ProductCategory, ProductType, VzfData } from 'graphql/types'
import { AppState } from 'store/store'

export const stateToVZF = (state: AppState): VzfData => {
    const productCategories: ProductCategory[] = []
    const multipleSelectOptionList: MultipleSelectOptions[] = []
    state.generalState.selectedProductCategories.forEach((selectedProductCategory) => {
        const selectedProduct = selectedProductCategory.selectedProduct
        // Get selected product category data from available categories
        const productCategory = state.generalState.availableProductCategories.find(
            (availableProductCategory) => availableProductCategory.id === selectedProductCategory.id,
        )
        if (!productCategory || !selectedProduct) return

        // Get selected product data from available products
        const product = productCategory.products.find((availableProduct) => availableProduct.id === selectedProduct.id)
        if (!product) return

        // Get all selected product type data from available product types
        const productTypes: ProductType[] = []
        selectedProduct.productTypes.forEach((selectedProductType) => {
            const productType = product.productTypes.find(
                (availableProductType) => availableProductType.id === selectedProductType.id,
            )
            if (!productType) return

            // Get all selected option category data from available option categories
            const optionCategories: OptionCategory[] = []
            selectedProductType.optionCategories.forEach((selectedOptionCategory) => {
                const optionCategory = productType.category.find(
                    (availableOptionCategory) => availableOptionCategory.id === selectedOptionCategory.id,
                )
                if (!optionCategory) return

                // Get all selected option data from available options
                const options: Option[] = []
                selectedOptionCategory.selectedOptions.forEach((selectedOption) => {
                    const option = optionCategory.options.find(
                        (availableOption) => availableOption.id === selectedOption,
                    )
                    if (option) options.push(option)
                })

                optionCategories.push({ ...optionCategory, options })
            })

            productTypes.push({ ...productType, category: optionCategories })
        })

        productCategories.push({ ...productCategory, products: [{ ...product, productTypes }] })
    })

    state.generalState.optionsMultipleSelect.forEach((value, key) => {
        multipleSelectOptionList.push({
            key,
            value,
        })
    })

    const vzf: VzfData = {
        __typename: 'VZFData',
        selectedProductCategories: JSON.stringify(productCategories),
        startOfMarketing: state.generalState.startOfMarketing,
        startOfDelivery: state.generalState.startOfDelivery,
        zipcode: state.availabilityCheck.zip,
        city: state.availabilityCheck.selectedCity,
        street: state.availabilityCheck.selectedStreet,
        houseNumber: state.availabilityCheck.selectedHouseNumber,
        additionalInfo: '',
        email: state.generalState.vzfMail,
        multipleSelectOptionList,
        customizeType: state.generalState.orderProcessType,
    }
    return vzf
}
