import * as dateFns from 'date-fns'

export function getRedirectUri(env: 'development' | 'production' | 'test'): string {
    return env === 'production' ? window.location.origin + '/admin/' : window.location.origin
}

export const formatStringToDate = (date: string): Date =>
    dateFns.parse(date, 'dd.MM.yyyy', new Date('2021-01-01T00:00:00.000Z'))

export const formatDateToString = (date: Date): string => dateFns.format(new Date(date), 'dd.MM.yyyy')

export const mailIsValid = (email: string): boolean => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
    )
}

export type BirthdayValidation = 'valid' | 'invalid' | 'tooYoung' | 'tooOld'

export const birthDateIsValid = (birthDate: string): BirthdayValidation => {
    const date = formatStringToDate(birthDate)
    const maxDate = dateFns.sub(new Date(), {
        years: 18,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })
    const minDate = dateFns.set(new Date(), {
        year: 1900,
        month: 0,
        date: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })
    if (isNaN(date.getTime())) return 'invalid'
    if (dateFns.isAfter(date, maxDate)) return 'tooYoung'
    if (dateFns.isBefore(date, minDate)) return 'tooOld'
    return 'valid'
}

export const salutationIsCompany = (salutation: string): boolean => {
    return salutation === 'Firma'
}

// checks if input is a valid phone number
export const isPhoneNumber = (phoneNumber: string): boolean => {
    // regexp for this kind of number: +(123) - 456-78-90
    const phoneRegExp = /^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g
    return phoneRegExp.test(phoneNumber)
}

// replaces the input with all valid phone number characters
// valid chars: 0-9 () / -
export const validatePhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/[^+()0-9-// ]/g, '')
}
