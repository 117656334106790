import { parse } from 'date-fns'
import { Customize, PostCodeDetail } from 'graphql/types'
import { Dispatch, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import {
    PortabilityAddress,
    PortabilityContractHolder,
    PortabilityTelephone,
    PortabilityViewFields,
} from 'store/PortabilityState/PortabilityState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'

interface PortabilityViewReducerProps {
    addOption: (field: PortabilityViewFields) => void
    address: PortabilityAddress
    contractHolderOptions: PortabilityContractHolder[]
    currentView: ViewType
    customizeJsData: Customize | undefined
    disabledSubmit: boolean
    endOfContract: string
    isOptionEmpty: (option: PortabilityTelephone | PortabilityContractHolder) => boolean
    missingFields: string[]
    onChange: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    phoneOptions: PortabilityTelephone[]
    removeOption: (field: PortabilityViewFields) => void
    providerOptions: string[]
    selectedProvider: string
    selectedRadios: string[]
    phoneNumbersError: (field: string, index: number) => boolean
    handleAddressSelected: (data: PostCodeDetail) => void
    handleSelectProvider: (_: React.ChangeEvent<any>, value: string) => void
    handleInputProvider: (event: React.ChangeEvent<HTMLInputElement>) => void
    B2B: boolean
}

export const usePortabilityViewReducer = (): PortabilityViewReducerProps => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions | PortabilityStateActions>>()
    const {
        address,
        contractHolderOptions,
        currentView,
        customizeJsData,
        endOfContract,
        phoneOptions,
        providerOptions,
        selectedProvider,
        selectedRadios,
        inLineErrors,
    } = useSelector((appState: AppState) => {
        return {
            address: appState.portabilityState.address,
            contractHolderOptions: appState.portabilityState.contractHolderOptions,
            currentView: appState.generalState.currentView,
            customizeJsData: appState.generalState.customizeJsData,
            endOfContract: appState.portabilityState.endOfContract,
            inLineErrors: appState.generalState.inLineErrors,
            phoneOptions: appState.portabilityState.phoneOptions,
            providerOptions: appState.portabilityState.providerOptions,
            selectedProvider: appState.portabilityState.selectedProvider,
            selectedRadios: appState.portabilityState.selectedRadios,
        }
    })
    const [missingFields, setMissingFields] = useState<string[]>([])

    const addErrorToPortability = useCallback(
        (error: string) => {
            dispatch({
                type: GeneralStateAction.ADD_ERROR_TO_ERROR_CATEGORY,
                payload: {
                    category: ViewType.PORTABILITY_DETAILS,
                    error,
                },
            })
        },
        [dispatch],
    )

    const removeErrorFromPortability = useCallback(
        (error: string) =>
            dispatch({
                type: GeneralStateAction.REMOVE_ERROR_FROM_ERROR_CATEGORY,
                payload: { category: ViewType.PORTABILITY_DETAILS, error },
            }),
        [dispatch],
    )

    const setPhoneOptions = useCallback(
        (payload: PortabilityTelephone[]) => {
            setMissingFields((missingFields) =>
                missingFields.filter((field) => field !== 'areaCode' && field !== 'phoneOption'),
            )
            removeErrorFromPortability('areaCodesMustBeTheSame')
            removeErrorFromPortability('phoneOptionsMustBeFilled')
            dispatch({ type: PortabilityStateAction.SET_PHONE_OPTIONS, payload })
        },
        // eslint-disable-next-line
        [dispatch],
    )

    const setEndOfContract = useCallback(
        (payload: string) => {
            dispatch({ type: PortabilityStateAction.SET_END_OF_CONTRACT, payload })
        },
        [dispatch],
    )

    const setAddress = useCallback(
        (payload: PortabilityAddress) => {
            dispatch({ type: PortabilityStateAction.SET_ADDRESS, payload })
        },
        [dispatch],
    )

    const setContractHolderOptions = useCallback(
        (payload: PortabilityContractHolder[]) => {
            dispatch({ type: PortabilityStateAction.SET_CONTRACT_HOLDER_OPTIONS, payload })
        },
        [dispatch],
    )

    const setSelectedRadios = useCallback(
        (payload: string[]) => {
            dispatch({ type: PortabilityStateAction.SET_SELECTED_RADIO, payload })
        },
        [dispatch],
    )

    const setSelectedProvider = useCallback(
        (payload: string) => {
            dispatch({ type: PortabilityStateAction.SET_SELECTED_PROVIDER, payload })
        },
        [dispatch],
    )

    const setInLineErrors = useCallback(
        (payload: string[]) => {
            dispatch({ type: GeneralStateAction.SET_INLINE_ERRORS, payload })
        },
        [dispatch],
    )

    const disabledSubmit = useMemo(
        () => {
            const missing = []
            const newErrors: string[] = [...inLineErrors]
            if (phoneOptions.length > 0) {
                const firstAreaCode = phoneOptions[0].areaCode
                if (firstAreaCode === '' || phoneOptions[0].number === '') return true
                if (phoneOptions.length > 1) {
                    for (let i = 1; i < phoneOptions.length; i++) {
                        if (phoneOptions[i].areaCode === '' || phoneOptions[i].number === '') {
                            return true
                        }
                        if (phoneOptions[i].areaCode === phoneOptions[0].areaCode) {
                            const errorIndex = newErrors.indexOf('areaCode' + i)
                            if (errorIndex !== -1) {
                                newErrors.splice(errorIndex, 1)
                            }
                        }
                    }
                    setInLineErrors(newErrors)
                }

                if (customizeJsData) {
                    if (customizeJsData.portabilityConfiguration.previousProvider && selectedProvider === '') {
                        return true
                    }
                    if (selectedRadios[0] === PortabilityViewFields.ADDRESS + 'No') {
                        if (
                            customizeJsData.portabilityConfiguration.previousContractAddress &&
                            Object.entries(address).filter(([k, v]) => (k === 'zip' ? v.length !== 5 : v === ''))
                                .length > 0
                        ) {
                            if (address.zip.length > 0 && address.zip.length !== 5) {
                                missing.push('contractAddress')
                                setMissingFields(missing)
                                addErrorToPortability('previousContractAddressZipMustBe5Digits')
                            }
                            return true
                        }
                    }
                    if (selectedRadios[1] === PortabilityViewFields.CONTRACT_HOLDER + 'No') {
                        if (
                            customizeJsData.portabilityConfiguration.previousContractHolder &&
                            contractHolderOptions.filter(
                                (co) => Object.entries(co).filter(([, v]) => v === '').length > 0,
                            ).length > 0
                        ) {
                            return true
                        }
                    }
                    if (selectedRadios[2] === PortabilityViewFields.TERMINATED_CONTRACT + 'No') {
                        if (customizeJsData.portabilityConfiguration.previousContractTermination) {
                            // only check when the entered date ist complete
                            if (/\d\d.\d\d.\d\d\d\d/.test(endOfContract)) {
                                const date = parse(endOfContract, 'dd.MM.yyyy', new Date()).getTime()
                                if (!isNaN(date)) {
                                    return false
                                } else {
                                    missing.push('terminationDate')
                                    setMissingFields(missing)
                                    addErrorToPortability('invalidDateFormat')
                                    return true
                                }
                            }
                        }
                    }
                }
            } else {
                return true
            }
            return false
        },
        // eslint-disable-next-line
        [
            address,
            contractHolderOptions,
            customizeJsData,
            endOfContract,
            phoneOptions,
            selectedProvider,
            selectedRadios,
        ],
    )

    const phoneNumbersError = (field: string, index: number): boolean => {
        return inLineErrors.includes(field + index)
    }
    const onChange = (field: PortabilityViewFields, label: string, index: number, value: string): void => {
        switch (field) {
            case PortabilityViewFields.TELEPHONE:
                const newPhoneOptions = [...phoneOptions]
                switch (label) {
                    case 'FIRST_LABEL':
                        newPhoneOptions[index].areaCode = value
                        setPhoneOptions(newPhoneOptions)
                        break
                    case 'SECOND_LABEL':
                        newPhoneOptions[index].number = value
                        setPhoneOptions(newPhoneOptions)
                        break
                }
                break
            case PortabilityViewFields.CONTRACT_HOLDER:
                const newContractHolderOptions = [...contractHolderOptions]
                const newSelectedRadios1 = [...selectedRadios]
                switch (label) {
                    case 'FIRST_LABEL':
                        newContractHolderOptions[index].firstName = value
                        setContractHolderOptions(newContractHolderOptions)
                        break
                    case 'SECOND_LABEL':
                        newContractHolderOptions[index].lastName = value
                        setContractHolderOptions(newContractHolderOptions)
                        break
                    case 'RADIO':
                        newSelectedRadios1[1] = value
                        setSelectedRadios(newSelectedRadios1)
                        break
                }
                break
            case PortabilityViewFields.ADDRESS:
                const newAddress = { ...address }
                const newSelectedRadios2 = [...selectedRadios]
                switch (label) {
                    case 'STREET':
                        newAddress.street = value
                        setAddress(newAddress)
                        break
                    case 'HOUSE_NUMBER':
                        newAddress.houseNumber = value
                        setAddress(newAddress)
                        break
                    case 'ZIP':
                        newAddress.zip = value
                        setMissingFields((missingFields) =>
                            missingFields.filter((field) => field !== 'contractAddress'),
                        )
                        removeErrorFromPortability('previousContractAddressZipMustBe5Digits')
                        setAddress(newAddress)
                        break
                    case 'CITY':
                        newAddress.city = value
                        setAddress(newAddress)
                        break
                    case 'RADIO':
                        newSelectedRadios2[0] = value
                        setMissingFields((missingFields) =>
                            missingFields.filter((field) => field !== 'contractAddress'),
                        )
                        removeErrorFromPortability('previousContractAddressZipMustBe5Digits')
                        setSelectedRadios(newSelectedRadios2)
                        break
                }
                break
            case PortabilityViewFields.TERMINATED_CONTRACT:
                let newEndOfContract = endOfContract
                const newSelectedRadios3 = [...selectedRadios]
                switch (label) {
                    case 'EndOfContract':
                        newEndOfContract = value
                        setMissingFields((missingFields) =>
                            missingFields.filter((field) => field !== 'terminationDate'),
                        )
                        removeErrorFromPortability('invalidDateFormat')
                        removeErrorFromPortability('terminationDateCantBeInTheFuture')
                        setEndOfContract(newEndOfContract)
                        break
                    case 'RADIO':
                        newSelectedRadios3[2] = value
                        setMissingFields((missingFields) =>
                            missingFields.filter((field) => field !== 'terminationDate'),
                        )
                        removeErrorFromPortability('invalidDateFormat')
                        removeErrorFromPortability('terminationDateCantBeInTheFuture')
                        setSelectedRadios(newSelectedRadios3)
                        break
                }
                break
            case PortabilityViewFields.PROVIDER:
                setSelectedProvider(value)
                break
        }
    }
    const addOption = (field: PortabilityViewFields): void => {
        if (field === PortabilityViewFields.TELEPHONE) {
            setPhoneOptions([...phoneOptions, { areaCode: '', number: '' }])
        }
        if (field === PortabilityViewFields.CONTRACT_HOLDER) {
            setContractHolderOptions([...contractHolderOptions, { firstName: '', lastName: '' }])
        }
    }
    const removeOption = (field: PortabilityViewFields): void => {
        if (field === PortabilityViewFields.TELEPHONE) {
            const newPhoneOptions = [...phoneOptions]
            newPhoneOptions.splice(newPhoneOptions.length - 1, 1)
            setPhoneOptions(newPhoneOptions)
        }
        if (field === PortabilityViewFields.CONTRACT_HOLDER) {
            const newContractHolderOptions = [...contractHolderOptions]
            newContractHolderOptions.splice(newContractHolderOptions.length - 1, 1)
            setContractHolderOptions(newContractHolderOptions)
        }
    }

    const isOptionEmpty = (option: PortabilityTelephone | PortabilityContractHolder): boolean => {
        return Object.entries(option).filter(([, v]) => v === '').length > 0
    }

    const handleAddressSelected = (data: PostCodeDetail) => {
        setAddress({
            street: data.address.street,
            zip: data.address.postcode,
            houseNumber: data.address.buildingNumber !== -1 ? data.address.buildingNumber.toString() : '',
            city: data.address.locality,
        })
    }

    const handleSelectProvider = (_: React.ChangeEvent<any>, value: string) => {
        onChange(PortabilityViewFields.PROVIDER, '', -1, value)
    }

    const handleInputProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(PortabilityViewFields.PROVIDER, '', -1, event.target.value)
    }

    const { B2B } = useURLParams()

    return {
        addOption,
        address,
        contractHolderOptions,
        currentView,
        customizeJsData,
        disabledSubmit,
        endOfContract,
        isOptionEmpty,
        missingFields,
        onChange,
        phoneOptions,
        removeOption,
        providerOptions,
        selectedProvider,
        selectedRadios,
        phoneNumbersError,
        handleAddressSelected,
        handleSelectProvider,
        handleInputProvider,
        B2B,
    }
}
