import { Box, Collapse, Grid, TextField, Typography } from '@material-ui/core'
import ConfigurationItem from 'components/ConfigurationItem'
import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import React from 'react'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'

interface RadioOptionsFormProps {
    mainTitle: string
    additionalLines?: string[]
    configurationOptions: ConfigurationItemOption[]
    textFieldTriggerValue: string
    bodyText?: string
    onTextFieldChange: (value: string) => void
    onSelectOption: (value: string) => void
    initialSelectedOption?: string | null
    initialTextFieldValue?: string
}

const RadioOptionsForm = ({
    mainTitle,
    additionalLines,
    configurationOptions,
    textFieldTriggerValue,
    bodyText,
    onSelectOption,
    onTextFieldChange,
    initialSelectedOption = null,
    initialTextFieldValue = '',
}: RadioOptionsFormProps): JSX.Element => {
    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        onTextFieldChange(newValue)
    }

    return (
        <Box mt={4}>
            <Typography variant="h1">{mainTitle}</Typography>
            {additionalLines &&
                additionalLines.map((line, index) => (
                    <Typography key={index} variant="h4">
                        {line}
                    </Typography>
                ))}
            <Grid container spacing={1}>
                {configurationOptions.map((option) => {
                    return (
                        <React.Fragment key={option.value}>
                            <Grid item xs={12}>
                                <ConfigurationItem
                                    type={OptionType.RADIO}
                                    option={option}
                                    selected={initialSelectedOption === option.value}
                                    onSelect={onSelectOption}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse
                                    unmountOnExit={true}
                                    in={
                                        initialSelectedOption === textFieldTriggerValue &&
                                        option.value === textFieldTriggerValue
                                    }
                                >
                                    <TextField
                                        label={bodyText}
                                        variant={'outlined'}
                                        fullWidth={true}
                                        value={initialTextFieldValue}
                                        onChange={handleTextFieldChange}
                                    />
                                </Collapse>
                            </Grid>
                        </React.Fragment>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default RadioOptionsForm
