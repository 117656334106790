import { useMutation } from '@apollo/client'
import SaveOrderProgress from 'graphql/mutations/SaveOrderProgress'
import { Mutation } from 'graphql/types'
import { Dispatch, SetStateAction, useState } from 'react'
import { useSelector } from 'react-redux'
import { CombinedState } from 'redux'
import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import { BankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import { ContactDataState } from 'store/ContactData/ContactData.reducer'
import { GeneralState, Page } from 'store/GeneralState/GeneralState.reducer'
import { PortabilityState } from 'store/PortabilityState/PortabilityState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { convertStateToOrderData } from 'utils/convertStateToOrderData'
import { encryption, generateKeyAndExportIt } from 'utils/crypto'

export enum SaveOrderProgessState {
    READY,
    SENDING,
    SENT,
}

interface SaveProgressStateReturn {
    currentPage: number
    state: AppState
    open: boolean
    pagesList: Page[]
    setOpen: Dispatch<SetStateAction<boolean>>
    dataPrivacyChecked: boolean
    setDataPrivacyChecked: Dispatch<SetStateAction<boolean>>
    email: string
    confirmEmail: string
    errorState: string
    saveOrderProgessState: SaveOrderProgessState
    setEmail: Dispatch<SetStateAction<string>>
    setConfirmEmail: Dispatch<SetStateAction<string>>
    setErrorState: Dispatch<SetStateAction<string>>
    onSendClick: (
        state: CombinedState<{
            availabilityCheck: AvailabilityCheckState
            bankDetails: BankDetailsState
            contactData: ContactDataState
            generalState: GeneralState
            portabilityState: PortabilityState
        }>,
        email: string,
        confirmEmail: string,
    ) => void
    emailError: boolean
    setEmailError: Dispatch<SetStateAction<boolean>>
    vzfID: string
}

const useSaveProgressState: () => SaveProgressStateReturn = () => {
    const { vzfID, currentPage, pagesList, state, clientData } = useSelector((appState: AppState) => ({
        state: appState,
        pagesList: appState.generalState.pagesList,
        currentPage: appState.generalState.currentPage,
        vzfID: appState.generalState.vzfID,
        clientData: appState.generalState.clientData,
    }))
    const [open, setOpen] = useState(false)
    const [dataPrivacyChecked, setDataPrivacyChecked] = useState(false)
    const [saveOrderProgessState, setSaveOrderProgessState] = useState<SaveOrderProgessState>(
        SaveOrderProgessState.READY,
    )
    const [saveOrderProgressCounter, setSaveOrderProgressCounter] = useState(1)
    const [errorState, setErrorState] = useState('')
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const { B2B } = useURLParams()

    const [saveOrderProgress] = useMutation<Mutation>(SaveOrderProgress, {
        onCompleted: (data) => {
            if (data.saveOrderProgress && data.saveOrderProgress.status === 'send_email') {
                setErrorState('send_email')
            }

            const timeout = saveOrderProgressCounter * 5000
            setSaveOrderProgessState(SaveOrderProgessState.SENT)
            setTimeout(() => {
                setSaveOrderProgessState(SaveOrderProgessState.READY)
                setEmail('')
                setConfirmEmail('')
                setDataPrivacyChecked(false)
            }, timeout)
            setSaveOrderProgressCounter(saveOrderProgressCounter + 1)
        },
    })

    const onSendClick = (
        state: CombinedState<{
            availabilityCheck: AvailabilityCheckState
            bankDetails: BankDetailsState
            contactData: ContactDataState
            generalState: GeneralState
            portabilityState: PortabilityState
        }>,
        email: string,
        confirmEmail: string,
    ): void => {
        if (email === confirmEmail) {
            setSaveOrderProgessState(SaveOrderProgessState.SENDING)
            setErrorState('')
            let salutation = ''
            if (
                state.contactData.personalSalutation.trim().length !== 0 &&
                state.contactData.personalName.trim().length !== 0 &&
                state.contactData.personalLastName.trim().length !== 0
            ) {
                switch (state.contactData.personalSalutation) {
                    case 'Firma':
                        salutation = state.contactData.personalName + ' ' + state.contactData.personalLastName
                        break
                    case 'Divers':
                        salutation = state.contactData.personalName + ' ' + state.contactData.personalLastName
                        break
                    default:
                        salutation =
                            state.contactData.personalSalutation +
                            ' ' +
                            state.contactData.personalName +
                            ' ' +
                            state.contactData.personalLastName
                }
            }
            if (email === confirmEmail) {
                const orderData = convertStateToOrderData(state, B2B)
                generateKeyAndExportIt().then(([key, hexKey]) => {
                    encryption(orderData, key).then((encryptedData) => {
                        saveOrderProgress({
                            variables: {
                                email,
                                data: encryptedData,
                                key: hexKey,
                                salutation: salutation,
                                vzfID,
                                clientID: clientData ? clientData.clientID : undefined,
                                orderProcessType: state.generalState.orderProcessType,
                            },
                        })
                    })
                })
            }
        } else {
            setEmailError(true)
        }
    }

    return {
        currentPage,
        dataPrivacyChecked,
        email,
        errorState,
        onSendClick,
        open,
        pagesList,
        saveOrderProgessState,
        setDataPrivacyChecked,
        setEmail,
        setErrorState,
        setOpen,
        state,
        confirmEmail,
        setConfirmEmail,
        emailError,
        setEmailError,
        vzfID,
    }
}

export default useSaveProgressState
