import { Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'

export interface HelpLinkProps {
    telephonenumber: string
}

const HelpLink = (props: HelpLinkProps): JSX.Element => {
    const { telephonenumber } = props
    const { t } = useTranslation()
    const regex = / /g
    return (
        <Link
            href={'tel:' + telephonenumber.replace(regex, '')}
            {...TestIDs.GET_HEADER_COMPONENT('LOGOICON_BUTTON')}
            className={'HelpLink'}
        >
            {t('help')}{' '}
            {/* <img
                src={process.env.PUBLIC_URL + '/customize/help.svg'}
                alt={'placeholder'}
                style={{ height: 20, width: 20 }}
            /> */}
            {telephonenumber}
        </Link>
    )
}

export default HelpLink
